import { Fragment, useContext } from "react";
import { ReactSortable } from "react-sortablejs";
import { SeedingContext } from "../../hooks/useSeedingContext";
import { SeedingPlayerButton } from "./SeedingPlayerButton";

export const SeedingEditPlayersList = () => {
    const {
        playerAnalysisList,
        setAnalysisStatus,
        setPlayerAnalysisList,
        setSwapHistory,
        reorderPlayersByElo,
    } = useContext(SeedingContext);

    return (
        <Fragment>
            <div className="d-flex flex-column p-3 border-end bg-light border-bottom">
                <div>
                    <button type="button" className="btn btn-secondary mb-3" onClick={reorderPlayersByElo}>
                        Reorder by Elo
                    </button>
                </div>

                <p className="mb-0">Drag players to shuffle their order.</p>
            </div>

            <ReactSortable
                onEnd={() => {
                    // manually update all seeds
                    const newList = [...playerAnalysisList];

                    let i = 1;

                    for (const p of newList) {
                        p.InitialSeed = i;
                        p.Seed = i;
                        i++;
                    }

                    setPlayerAnalysisList(newList);

                    // clear swap history and rebuild bracket
                    setSwapHistory([]);
                    setAnalysisStatus("RebuildingBracket");
                }}
                className="list-group overflow-y-scroll border-end flex-grow-1"
                list={playerAnalysisList}
                setList={setPlayerAnalysisList}
            >
                {playerAnalysisList.map(p => (
                    <SeedingPlayerButton btn={p} key={p.InitialSeed} showInitialSeed={true} className="cursor-move" />
                ))}
            </ReactSortable>
        </Fragment>
    );
};

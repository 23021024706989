import AusmashService, {
    AusmashPocketEloItem,
    AusmashPocketGame,
    AusmashPocketPlayerMatches,
    AusmashRegionIndexItem,
} from "ausmash-api";
import moment from "moment";
import { Dispatch, ReactNode, SetStateAction, useEffect, useState, createContext, useCallback } from "react";
import {
    SeedingAnalysisStatus,
    SeedingBracket,
    SeedingImportedPlayer,
    SeedingImportMethod,
    SeedingMatch,
    SeedingMatchAnalysis,
    SeedingPlayerAnalysis,
    SeedingPlayerFindStatus,
    SeedingPlayerOpponentAnalysis,
    SeedingRound,
    SeedingSelectedBracket,
    SeedingSettings,
    SeedingSmashGGImportError,
    SeedingSwapAnalysis,
} from "../interfaces/seeding/SeedingInterfaces";
import { SmashGGEvent, SmashGGEventRequest, SmashGGPhase } from "../interfaces/smashgg/SmashGGTournament";
import { getSmashGGEvent, postSeedsToSmashGG } from "../services/SmashGGService";

// this is declared here so that the constructor isn't called repeatedly
const service = new AusmashService("LQUW2R222SIH8Y322GM4");

export const useSeedingContext = () => {
    // state

    // which method of import is selected, if any
    const [importMethod, setImportMethod] = useState<SeedingImportMethod>("None");

    // determines whether to reorder seeds by Elo
    const [reorderByElo, setReorderByElo] = useState(false);

    // status of matching all imported players up with Elo players
    const [findPlayersStatus, setFindPlayersStatus] = useState<SeedingPlayerFindStatus>("Loading");

    // status of loading match history and analysing bracket
    const [analysisStatus, setAnalysisStatus] = useState<SeedingAnalysisStatus>("Initial");

    // whether the user is viewing the selected bracket or the default bracket
    const [selectedBracket, setSelectedBracket] = useState<SeedingSelectedBracket>("reseeded");

    // tells the system to stop loading match histories
    const [isCancelling, setIsCancelling] = useState(false);

    // fails if either the games or regions fails to load
    const [initialLoadFailed, setInitialLoadFailed] = useState(false);

    // data

    // all the games which can be used as a data source
    const [games, setGames] = useState<Array<AusmashPocketGame>>([]);

    // the game which all data uses
    const [selectedGame, setSelectedGame] = useState<AusmashPocketGame>();

    // the regions which you can quickly generate a bracket from
    const [regions, setRegions] = useState<Array<AusmashRegionIndexItem>>([]);

    // players from the initial import
    const [importedPlayers, setImportedPlayers] = useState<Array<SeedingImportedPlayer>>([]);

    // players who could not be found in the Elo list
    const [missingPlayers, setMissingPlayers] = useState<Array<SeedingImportedPlayer>>([]);

    // the missing player who the user is currently looking to fix
    const [selectedMissingPlayer, setSelectedMissingPlayer] = useState<SeedingImportedPlayer>();

    // text used to find missing players
    const [missingPlayerText, setMissingPlayerText] = useState("");

    // filtered list of players for tagging
    const [missingPlayerSuggestions, setMissingPlayerSuggestions] = useState<Array<AusmashPocketEloItem>>([]);

    // a collection of every player's Elo
    const [players, setPlayers] = useState<Array<AusmashPocketEloItem>>([]);

    // the result of seeding the players initial selection
    const [initialBracket, setInitialBracket] = useState<SeedingBracket>();

    // log of all swaps
    const [swapHistory, setSwapHistory] = useState<Array<SeedingSwapAnalysis>>([]);

    // swaps which the user has dismissed
    const [dismissedSwaps, setDismissedSwaps] = useState<Array<SeedingSwapAnalysis>>([]);

    // reseeded analysis
    const [playerAnalysisList, setPlayerAnalysisList] = useState<Array<SeedingPlayerAnalysis>>([]);

    // the last time the selected player has played each opponent
    const [selectedPlayerAnalysis, setSelectedPlayerAnalysis] = useState<SeedingPlayerAnalysis>();

    // the result of running the algorithm on the players being seeded
    const [reseededBracket, setReseededBracket] = useState<SeedingBracket>();

    // the suggested swaps
    const [suggestedSwaps, setSuggestedSwaps] = useState<Array<SeedingSwapAnalysis>>([]);

    // if this is true then we are adding all available suggestions
    const [applyAllSuggestions, setApplyAllSuggestions] = useState(false);

    // the imported start.gg event
    const [smashGGEvent, setSmashGGEvent] = useState<SmashGGEventRequest>();

    // selected smash GG phase
    const [selectedSmashGGPhase, setSelectedSmashGGPhase] = useState<SmashGGPhase>();

    // selected smash GG event
    const [selectedSmashGGEvent, setSelectedSmashGGEvent] = useState<SmashGGEvent>();

    // master analysis list that contains analysis for every entrant in an event
    const [masterPlayerAnalysisList, setMasterPlayerAnalysisList] = useState<Array<SeedingPlayerAnalysis>>([]);

    // if this is true then we're winding back all the current swaps
    const [undoingAllSwaps, setUndoingAllSwaps] = useState(false);

    // the region filter for missing players
    const [selectedRegionShort, setSelectedRegionShort] = useState<string>();

    // the event for start.gg
    const [smashGGUrl, setSmashGGUrl] = useState("");

    // errors from importing from start.gg
    const [importErrors, setImportErrors] = useState<Array<SeedingSmashGGImportError>>([]);

    // settings used to find swaps
    const [settings, setSettings] = useState<SeedingSettings>(
        // default settings
        {
            MaxSwapSeedDistance: 1,
            DangerZone: moment().add(-4, "weeks").toDate(),
        },
    );

    // methods

    // reads players from an Ausmash seeding export text file
    const importPlayersFromText = (content: string) => {
        let playerId = 1;

        // parse file
        const lines = content
            .toString()
            .split("\n")
            .map(t => t.trim())
            .filter(t => t);

        const imports: Array<SeedingImportedPlayer> = [];

        for (const l of lines) {
            // parse each player
            const indexOfFirstSpace = l.indexOf(" ");

            // check for players with region as prefix
            const playerPrefix = l.substring(0, indexOfFirstSpace + 1);
            let found = false;

            for (const r of regions) {
                // skip if already found
                if (found) {
                    continue;
                }

                const regionPrefix = `${r.Short} `;

                if (regionPrefix === playerPrefix) {
                    const region = l.substring(0, indexOfFirstSpace);
                    const name = l.substring(indexOfFirstSpace + 1);
                    const p: SeedingImportedPlayer = { Region: region, Name: name, ID: playerId };
                    found = true;
                    imports.push(p);
                }

                continue;
            }

            // if we get here then just import their name as a string
            if (!found) {
                const p: SeedingImportedPlayer = { Region: "", Name: l, ID: playerId };
                imports.push(p);
            }

            playerId++;
        }

        setImportedPlayers(imports);

        // trigger importing
        setImportMethod("File");
        setFindPlayersStatus("Importing");
    };

    const findPlayersFromImport = (ip: SeedingImportedPlayer) => {
        // check the Smash gg player ID first
        if (ip.SmashGGPlayerID) {
            const smashGGMatch = players.filter(x => x.SmashGGPlayerID === ip.SmashGGPlayerID);

            if (smashGGMatch.length === 1) {
                // exact match for start.gg ID
                return smashGGMatch;
            }
        }

        // if we match the name and region and there's one items it's a match
        const nameAndStateMatches = players.filter(
            x => x.PlayerName.toLowerCase() === ip.Name.toLowerCase() && x.PlayerRegionShort === ip.Region,
        );

        if (nameAndStateMatches.length === 1) {
            return nameAndStateMatches;
        }

        // search for a text match only
        // if there's multiple matches then it's players with the same name in different regions
        return players.filter(x => x.PlayerName.toLowerCase() === ip.Name.toLowerCase());
    };

    const addAnalysisForPlayer = (p: AusmashPocketEloItem, seed: number, importedPlayer?: SeedingImportedPlayer) => {
        let s = seed;

        // if the player comes from start.gg, get their seed from the seed list
        if (selectedSmashGGPhase) {
            if (importedPlayer?.SmashGGSeeds?.length) {
                const seedData = importedPlayer.SmashGGSeeds.find(x => x.phase.id === selectedSmashGGPhase.id);

                if (seedData) {
                    s = seedData.seedNum;
                }
            }
        }

        const newPlayer: SeedingPlayerAnalysis = {
            id: s,
            PlayerName: p.PlayerName,
            PlayerID: p.PlayerID,
            RegionShort: p.PlayerRegionShort,
            Opponents: [],
            Seed: s,
            InitialSeed: s,
            IsLoaded: false,
            Elo: p.Elo,
            SmashGGPlayerID: importedPlayer?.SmashGGPlayerID,
            SmashGGSeeds: importedPlayer?.SmashGGSeeds,
        };

        return newPlayer;
    };

    // reads players an import - this can be from text or start.gg
    const importPlayers = () => {
        // filter master list by Elo
        const missingPlayers: Array<SeedingImportedPlayer> = [];
        const succeededPlayers: Array<SeedingPlayerAnalysis> = [];

        // sort the players however they should be
        importedPlayers.sort((a, b) => (a.ID < b.ID ? -1 : 1));

        // try to find Elo for players from text
        let s = 1;

        importedPlayers.map(ip => {
            const results = findPlayersFromImport(ip);

            if (results.length === 1) {
                const [eloPlayer] = results;
                succeededPlayers.push(addAnalysisForPlayer(eloPlayer, s, ip));
            } else {
                missingPlayers.push(ip);
            }

            s++;
        });

        setMasterPlayerAnalysisList(succeededPlayers);
        setMissingPlayers(missingPlayers);

        // if there's no missing players then proceed with loading data
        if (missingPlayers.length === 0) {
            setFindPlayersStatus("Reordering");
            setImportedPlayers([]);
        } else {
            setFindPlayersStatus("MissingPlayers");
        }
    };

    // uses the top 32 players in a given region as the data source
    const importSelectedPlayersFromRegion = (regionId?: number) => {
        // get the data source
        const data = regionId ? players.filter(x => x.PlayerRegionID === regionId).slice(0, 32) : players.slice(0, 32);

        const convertedList: Array<SeedingPlayerAnalysis> = [];

        // create analysis for each player
        for (let s = 0; s < data.length; s++) {
            const converted = addAnalysisForPlayer(data[s], s + 1);
            convertedList.push(converted);
        }

        setMasterPlayerAnalysisList(convertedList);
        setImportMethod("Automatic");
        setFindPlayersStatus("Reordering");
    };

    // gets a list of players from start.gg
    const importSelectedPlayersFromSmashGG = (url: string) => {
        setFindPlayersStatus("Loading");

        getSmashGGEvent(url).then(data => {
            setFindPlayersStatus("Initial");

            // if the event is null then it failed to find it
            if (data.result?.data.event == null) {
                // add smash gg error
                setImportErrors([
                    ...importErrors,
                    {
                        id: importErrors.length + 1,
                        name: `Could not import ${url}`,
                    },
                ]);
                return;
            }

            // store bracket for later
            setImportMethod("SmashGG");
            setSmashGGUrl(url);
            setSmashGGEvent(data.result);

            // get event
            setSelectedSmashGGEvent(data.result.data.event);

            const imports: Array<SeedingImportedPlayer> = [];

            let s = 1;

            for (const entrant of data.result.data.event.entrants.nodes) {
                const [participant] = entrant.participants;

                // get basic info first
                const name = participant.player.gamerTag;
                const region = participant.player.user?.location?.state ?? "";

                // add player to import list
                const p: SeedingImportedPlayer = {
                    ID: s, // default seed to initial order
                    Name: name,
                    Region: region,
                    SmashGGPlayerID: participant.player.id,
                };

                // push seed data into player if it exists
                if (entrant.seeds != null) {
                    p.SmashGGSeeds = entrant.seeds;

                    // tODO: match seed for selected event
                    // this is not always the correct seed
                    p.ID = entrant.seeds[entrant.seeds.length - 1].seedNum;
                }

                imports.push(p);

                s++;
            }

            // sort by seeds
            setImportedPlayers(imports);

            // trigger importing
            setFindPlayersStatus("Importing");
        });
    };

    // sort method
    const sortBySeed = (a: SeedingPlayerAnalysis, b: SeedingPlayerAnalysis) => {
        if (a.InitialSeed > b.InitialSeed) {
            return 1;
        } else if (a.InitialSeed < b.InitialSeed) {
            return -1;
        }

        return 0;
    };

    // compares two opponents and returns a 1 if player a is a better opponent
    const sortOpponents = (a: SeedingPlayerOpponentAnalysis, b: SeedingPlayerOpponentAnalysis) => {
        // if they have never played then they are a perfect opponent
        if (a.DaysSinceLastPlayed == null) {
            return 1;
        } else if (b.DaysSinceLastPlayed == null) {
            return -1;
        }

        // we mainly want to find people who haven't played in many weeks
        if (a.DaysSinceLastPlayed > b.DaysSinceLastPlayed) {
            return 1;
        } else if (a.DaysSinceLastPlayed < b.DaysSinceLastPlayed) {
            return -1;
        }

        // sort by times played if nothing else
        if (a.TimesPlayed < b.TimesPlayed) {
            return 1;
        } else if (a.TimesPlayed > b.TimesPlayed) {
            return -1;
        } else {
            // nothing to split them
            return 0;
        }
    };

    // convert matches to recommendation
    const analyseOpponentsForPlayer = (player: SeedingPlayerAnalysis, data?: AusmashPocketPlayerMatches) => {
        // iterate through opponents
        let i = 1;

        for (const p of masterPlayerAnalysisList) {
            // don't add yourself
            if (p.PlayerID === player.PlayerID) {
                continue;
            }

            const opp: SeedingPlayerOpponentAnalysis = {
                ID: i,
                PlayerName: p.PlayerName,
                PlayerID: p.PlayerID,
                TimesPlayed: 0,
                InitialSeed: p.InitialSeed,
            };

            if (data) {
                for (const e of data.Events) {
                    for (const m of e.Matches) {
                        if (m.WinnerID == p.PlayerID || m.LoserID == p.PlayerID) {
                            // increment set count
                            opp.TimesPlayed++;

                            // determine weeks since
                            const a = moment();
                            const b = moment(e.Date);

                            const WeeksSinceLastPlayed = a.diff(b, "weeks");
                            const DaysSinceLastPlayed = a.diff(b, "days");

                            // only add if it's either the first entry or less than the current number
                            if (opp.DaysSinceLastPlayed == null || opp.DaysSinceLastPlayed > DaysSinceLastPlayed) {
                                opp.WeeksSinceLastPlayed = WeeksSinceLastPlayed;
                                opp.DaysSinceLastPlayed = DaysSinceLastPlayed;
                            }
                        }
                    }
                }
            }

            player.Opponents.push(opp);

            i++;
        }

        player.Opponents.sort(sortOpponents);
        player.IsLoaded = true;

        return player;
    };

    // links an imported player to their Elo
    const tagPlayer = async (player: AusmashPocketEloItem, seed: number) => {
        let p: SeedingPlayerAnalysis | null = null;

        // convert player
        if (selectedMissingPlayer) {
            p = addAnalysisForPlayer(player, seed, selectedMissingPlayer);
        } else if (selectedPlayerAnalysis) {
            p = addAnalysisForPlayer(player, seed);

            if (p.PlayerID && selectedGame) {
                const data = await service.Pocket.getMatches(p.PlayerID, selectedGame.ID);

                p = analyseOpponentsForPlayer(p, data);
            }
        }

        if (p) {
            // transfer player over to selected players
            const newPlayers = [...masterPlayerAnalysisList, p];
            setMasterPlayerAnalysisList(newPlayers);

            if (selectedPlayerAnalysis) {
                const newList = [...playerAnalysisList.filter(x => x.Seed !== selectedPlayerAnalysis.Seed), p];

                // add analysis item of new player to each existing player
                for (const player of newList) {
                    const playerData = p.Opponents.find(x => x.ID === player.id);

                    const newOpponent: SeedingPlayerOpponentAnalysis = {
                        ID: p.id,
                        PlayerID: p.PlayerID,
                        InitialSeed: p.InitialSeed,
                        PlayerName: p.PlayerName,
                        TimesPlayed: playerData?.TimesPlayed ?? 0,
                        DaysSinceLastPlayed: playerData?.DaysSinceLastPlayed,
                        WeeksSinceLastPlayed: playerData?.WeeksSinceLastPlayed,
                    };

                    player.Opponents.push(newOpponent);
                    player.Opponents.sort(sortOpponents);
                }

                newList.sort(sortBySeed);

                setPlayerAnalysisList(newList);
                setSelectedPlayerAnalysis(p);
            }

            if (selectedMissingPlayer) {
                // remove from old list
                const remainingPlayers = [...missingPlayers.filter(x => x.ID !== selectedMissingPlayer.ID)];
                setMissingPlayers(remainingPlayers);
            }
        }
    };

    // add the selected player as a new player
    const addNewPlayer = () => {
        //  add new player
        const p: SeedingPlayerAnalysis = {
            id: 0,
            Seed: 0,
            InitialSeed: 0,
            PlayerName: "",
            IsLoaded: false,
            Opponents: [],
            RegionShort: "",
            Elo: 0,
        };

        // determine seed
        if (selectedMissingPlayer) {
            let seed = selectedMissingPlayer.ID;

            // get the start.gg seed if needed
            if (selectedSmashGGPhase) {
                const s = selectedMissingPlayer.SmashGGSeeds?.find(x => x.phase.id === selectedSmashGGPhase.id);

                if (s) {
                    seed = s.seedNum;
                }
            }

            // adding during import
            p.id = seed;
            p.Seed = seed;
            p.InitialSeed = seed;
            p.PlayerName = selectedMissingPlayer.Name;
            p.RegionShort = selectedMissingPlayer.Region;
            p.SmashGGPlayerID = selectedMissingPlayer.SmashGGPlayerID;
            p.SmashGGSeeds = selectedMissingPlayer.SmashGGSeeds;
        } else {
            // adding during edit players
            const newSeed = playerAnalysisList.length + 1;

            p.id = newSeed;
            p.Seed = newSeed;
            p.InitialSeed = newSeed;
            p.PlayerName = missingPlayerText;
            p.RegionShort = "";
        }

        // add player to list
        setMasterPlayerAnalysisList([...masterPlayerAnalysisList, p]);
        setPlayerAnalysisList([...playerAnalysisList, p]);

        // remove player from missing list
        setMissingPlayers([...missingPlayers.filter(x => x != selectedMissingPlayer)]);
    };

    // adds a player from the Elo list while editing players
    const addExistingPlayer = (p: AusmashPocketEloItem) => {
        if (selectedGame) {
            service.Pocket.getMatches(p.PlayerID, selectedGame.ID).then(data => {
                const updateList = (
                    list: Array<SeedingPlayerAnalysis>,
                    setList: (value: React.SetStateAction<SeedingPlayerAnalysis[]>) => void,
                ) => {
                    // insert player into the analysis list
                    const empty = addAnalysisForPlayer(p, list.length + 1);
                    const analysis = analyseOpponentsForPlayer(empty, data);

                    const newList = [...list, analysis].map(a => {
                        const newOpponents = [...a.Opponents];

                        // add new opponent data from my own analysis
                        const newAnalysis = analysis.Opponents.find(
                            x => x.PlayerID != null && x.PlayerID == a.PlayerID,
                        );

                        if (newAnalysis) {
                            const newOpponent = {
                                ...newAnalysis,
                                PlayerID: p.PlayerID,
                                PlayerName: p.PlayerName,
                                ID: newOpponents.length + 1,
                            };

                            newOpponents.push(newOpponent);

                            newOpponents.sort(sortOpponents);
                        }

                        return { ...a, Opponents: newOpponents };
                    });

                    setList(newList);
                };

                updateList(masterPlayerAnalysisList, setMasterPlayerAnalysisList);
                updateList(playerAnalysisList, setPlayerAnalysisList);

                setAnalysisStatus("RebuildingBracket");
            });
        }
    };

    // reorders the analysis list by Elo
    const reorderPlayersByElo = () => {
        const sortList = (
            list: Array<SeedingPlayerAnalysis>,
            setList: Dispatch<SetStateAction<Array<SeedingPlayerAnalysis>>>,
        ) => {
            // first get new list
            const newList = [...list].sort((a, b) => {
                if (a.Elo > b.Elo) {
                    return -1;
                } else if (a.Elo < b.Elo) {
                    return 1;
                }

                return 0;
            });

            // update seeds in list
            let s = 1;

            for (const p of newList) {
                p.Seed = s;
                p.InitialSeed = s;
                s++;
            }

            // save new list
            setList(newList);
        };

        sortList(playerAnalysisList, setPlayerAnalysisList);
        sortList(masterPlayerAnalysisList, setMasterPlayerAnalysisList);
    };

    // reorders the analysis list by Elo
    const reorderPlayersBySeed = () => {
        // sort master list
        const newMaster = [...masterPlayerAnalysisList].sort(sortBySeed);

        let s = 1;

        for (const p of newMaster) {
            p.Seed = s;
            p.InitialSeed = s;
            s++;
        }

        setMasterPlayerAnalysisList(newMaster);

        // first get new list
        const newList = [...playerAnalysisList].sort(sortBySeed);

        // update seeds in list
        s = 1;

        for (const p of newList) {
            p.Seed = s;
            p.InitialSeed = s;
            s++;
        }

        // save new list
        setPlayerAnalysisList(newList);
    };

    // saves the current seeding
    const savePlayers = () => {
        // sort master list
        const newMaster = [...masterPlayerAnalysisList];

        for (const p of newMaster) {
            p.InitialSeed = p.Seed;
        }

        setMasterPlayerAnalysisList(newMaster);

        // first get new list
        const newList = [...playerAnalysisList].sort(sortBySeed);

        // update seeds in list
        for (const p of newList) {
            p.InitialSeed = p.Seed;
        }

        // save new list
        setPlayerAnalysisList(newList);

        // clear swap history
        setSwapHistory([]);
    };

    // determines the value of a match
    const analyseMatch = (match: SeedingMatch): SeedingMatchAnalysis | undefined => {
        if (match.Player2) {
            const opponents = match.Player1?.Opponents;

            if (opponents && opponents.length) {
                const opponent = opponents.find(x => x.PlayerID === match.Player2?.PlayerID);

                if (opponent) {
                    const analysis: SeedingMatchAnalysis = {
                        Value: opponent.WeeksSinceLastPlayed,
                        Value2: opponent.DaysSinceLastPlayed,
                    };

                    return analysis;
                }
            }
        }

        return undefined;
    };

    // checks the chosen players and loads the match history of the next player which isn't loaded
    const loadNextPlayerMatchHistory = () => {
        // ignore if cancelling
        if (isCancelling) {
            return;
        }

        const nextAnalysisList = masterPlayerAnalysisList.filter(x => x.IsLoaded == false);

        if (nextAnalysisList.length && selectedGame) {
            const [a] = nextAnalysisList;

            if (a.PlayerID == null) {
                // insert player into the analysis list
                const newAnalysis = analyseOpponentsForPlayer(a);

                const newList = [...masterPlayerAnalysisList.filter(x => x.id !== newAnalysis.id), newAnalysis];

                setMasterPlayerAnalysisList(newList);
                setPlayerAnalysisList(newList);

                return;
            } else {
                // if we don't have a recommendation for them then load one
                service.Pocket.getMatches(a.PlayerID, selectedGame.ID).then(data => {
                    // ignore if cancelling
                    if (isCancelling) {
                        return;
                    }

                    // insert player into the analysis list
                    const newAnalysis = analyseOpponentsForPlayer(a, data);

                    const newList = [...masterPlayerAnalysisList.filter(x => x.id !== newAnalysis.id), newAnalysis];

                    setMasterPlayerAnalysisList(newList);
                    setPlayerAnalysisList(newList);

                    // adjust the bracket
                    const bracket = reseededBracket;

                    if (bracket) {
                        for (const r of bracket.Rounds) {
                            for (const m of r.Matches) {
                                // analyse the match if it's possible
                                if (m.Player1 == null || m.Player2 == null) {
                                    // don't analyse if there's no player
                                    continue;
                                }

                                if (m.Analysis != null) {
                                    // don't analyse if already done
                                    continue;
                                }

                                const matchAnalysis = analyseMatch(m);

                                // save the analysis
                                if (matchAnalysis != null) {
                                    m.Analysis = matchAnalysis;
                                }
                            }
                        }
                    }

                    // update the bracket with any new analysis
                    setReseededBracket(bracket);
                });

                // if we reach here then we're loading a single player
                // don't go any further and wait to be triggered again
                return;
            }
        }

        // if we get to this point there there's no more players to analyse

        // trigger the analysis of each match
        setAnalysisStatus("BracketAnalysisRequired");
    };

    // builds a bracket from a list of recommendations
    const buildBracket = () => {
        // finds a player by their original seed
        const getRecommendationBySeed = (seed: number) => playerAnalysisList.find(x => x.Seed === seed);

        // determines how many rounds in winners need to be seeded for
        const roundCount = Math.ceil(Math.log2(playerAnalysisList.length));

        const result: SeedingBracket = {
            RoundCount: roundCount,
            Rounds: [],
        };

        let matchId = 1;

        for (let r = roundCount; r > 0; r--) {
            const round: SeedingRound = {
                Matches: [],
                RoundNumber: r,
            };

            // here we determine the number of matches required
            // so that we can create and fill them with players
            const requiredMatchCount = Math.pow(2, roundCount - r);
            const requiredPlayerCount = requiredMatchCount * 2;

            // build an array of player seeds to feed into the round
            const playerBank: Array<number> = [];

            for (let q = 1; q < requiredPlayerCount + 1; q++) {
                playerBank.push(q);
            }

            for (let m = 0; m < requiredMatchCount; m++) {
                const seedNumberToWin = playerBank.shift();
                const seedNumberToLose = playerBank.pop();

                if (seedNumberToWin && seedNumberToLose) {
                    const seededToWin = getRecommendationBySeed(seedNumberToWin);
                    const seededToLose = getRecommendationBySeed(seedNumberToLose);

                    const match: SeedingMatch = {
                        MatchID: matchId,
                        Player1: seededToWin,
                        Player2: seededToLose,
                    };

                    match.Analysis = analyseMatch(match);

                    matchId++;

                    round.Matches.push(match);
                }
            }

            result.Rounds.push(round);
        }

        return result;
    };

    const swapSeedsByNumber = (seed1: number, seed2: number, triggerRebuild = true) => {
        // update the reseeded players
        setPlayerAnalysisList(previousPlayers => {
            const seed1Player = previousPlayers.find(x => x.Seed === seed1);
            const seed2Player = previousPlayers.find(x => x.Seed === seed2);

            if (seed1Player && seed2Player) {
                const newSeed1: SeedingPlayerAnalysis = { ...seed1Player, Seed: seed2 };
                const newSeed2: SeedingPlayerAnalysis = { ...seed2Player, Seed: seed1 };

                const newSeeds = [
                    ...previousPlayers.filter(x => x.Seed !== seed1 && x.Seed !== seed2),
                    newSeed1,
                    newSeed2,
                ].sort((a, b) => {
                    if (a.Seed > b.Seed) {
                        return 1;
                    } else if (a.Seed < b.Seed) {
                        return -1;
                    }

                    return 0;
                });

                return newSeeds;
            }

            return previousPlayers;
        });

        if (triggerRebuild) {
            // clear out the suggestions as they will be analysed again
            setSuggestedSwaps([]);

            // trigger a rebuild of the bracket
            setAnalysisStatus("RebuildingBracket");
        }
    };

    const swapSeeds = (suggestion: SeedingSwapAnalysis) => {
        const { Match1, Match2 } = suggestion;

        const seed1 = Match1?.Player2?.Seed;
        const seed2 = Match2?.Player2?.Seed;

        if (seed1 && seed2) {
            swapSeedsByNumber(seed1, seed2);

            // log
            setSwapHistory(oldSwaps => [...oldSwaps, suggestion]);
        }
    };

    const undoLastSwap = useCallback(() => {
        if (swapHistory.length) {
            const newHistory = [...swapHistory];

            const lastSwap = newHistory.pop();

            if (lastSwap) {
                // perform the actual swap
                swapSeedsByNumber(lastSwap.Player1.Seed, lastSwap.Player2.Seed, false);

                // remove the suggestion
                setSwapHistory(newHistory);
            }
        }
    }, [swapHistory]);

    const reset = () => {
        setImportMethod("None");
        setFindPlayersStatus("Initial");
        setAnalysisStatus("Initial");
        setSmashGGUrl("");
        setPlayerAnalysisList([]);
        setInitialBracket(undefined);
        setReseededBracket(undefined);
        setSelectedMissingPlayer(undefined);
        setSelectedPlayerAnalysis(undefined);
        setSmashGGEvent(undefined);
        setSelectedSmashGGEvent(undefined);
        setSelectedSmashGGPhase(undefined);
        setSuggestedSwaps([]);
        setSwapHistory([]);
        setImportedPlayers([]);
        setDismissedSwaps([]);
        setMasterPlayerAnalysisList([]);
    };

    const checkIfMatchHasAlreadyBeenSuggested = (
        m1: SeedingMatch,
        m2: SeedingMatch,
        suggestions: Array<SeedingSwapAnalysis>,
    ) => {
        if (suggestions.find(x => x.Match1?.MatchID === m1.MatchID && x.Match2?.MatchID == m2.MatchID)) {
            return true;
        }

        if (suggestions.find(x => x.Match2?.MatchID === m1.MatchID && x.Match1?.MatchID == m2.MatchID)) {
            return true;
        }

        return false;
    };

    const generateSuggestionId = (
        p1InitialSeed: number,
        p2InitialSeed: number,
        p1CurrentSeed: number,
        p2CurrentSeed: number,
    ) => `s${p1InitialSeed}-${p2InitialSeed}_${p1CurrentSeed}-${p2CurrentSeed}`;

    const findSwapSuggestions = () => {
        // go through each round
        if (reseededBracket) {
            const reseededRounds: Array<SeedingRound> = [];
            const suggestions: Array<SeedingSwapAnalysis> = [];

            for (const round of reseededBracket.Rounds) {
                const { Matches } = round;

                // sort the matches so the ones at the start are the ideal ones to swap
                Matches.sort((a, b) => {
                    if (a.Analysis == null || a.Analysis.Value2 == null) {
                        return 1;
                    } else if (b.Analysis == null || b.Analysis.Value2 == null) {
                        return -1;
                    }

                    if (a.Analysis.Value2 > b.Analysis.Value2) {
                        return 1;
                    } else if (a.Analysis.Value2 < b.Analysis.Value2) {
                        return -1;
                    }

                    return 0;
                });

                // at this stage, we're using the first match in a round to see if we can improve it
                for (const matchToReview of Matches) {
                    // skip if it's not within the threshold for recency
                    const matchAnalysis = matchToReview.Analysis;

                    // if the match analysis is empty then they've never played
                    // in this case we never need to swap anything
                    if (matchAnalysis?.Value2 == null) {
                        continue;
                    }

                    // ignore matches not within the danger zone
                    if (matchAnalysis?.Value2 != null) {
                        const compareDate = moment().add(-matchAnalysis.Value2, "days").toDate();

                        if (compareDate < settings.DangerZone) {
                            continue;
                        }
                    }

                    // get the first player of the match who is seeded to win
                    const matchToReviewPlayer1 = matchToReview.Player1;
                    const matchToReviewPlayer2 = matchToReview.Player2;

                    if (matchToReviewPlayer1 && matchToReviewPlayer2) {
                        // get a list of all the opponents analysed for the highest seed player
                        const matchToReviewPlayer1Analysis = playerAnalysisList.find(
                            x => x.PlayerID === matchToReviewPlayer1.PlayerID,
                        );

                        if (matchToReviewPlayer1Analysis) {
                            // if there is at least one option here we should consider the swap
                            if (matchToReviewPlayer1Analysis.Opponents.length) {
                                for (const playerToSwap of matchToReviewPlayer1Analysis.Opponents) {
                                    // get the match of the person we want to swap to
                                    const matchToSwap = Matches.find(
                                        x => x.Player2?.InitialSeed === playerToSwap.InitialSeed,
                                    );

                                    if (matchToSwap && matchToSwap.MatchID !== matchToReview.MatchID) {
                                        // ignore the match if it's already been suggested
                                        if (
                                            checkIfMatchHasAlreadyBeenSuggested(matchToSwap, matchToReview, suggestions)
                                        ) {
                                            continue;
                                        }

                                        // determine if the match is outside of the max swap distance
                                        const p1 = matchToReview.Player2;
                                        const p2 = matchToSwap.Player2;

                                        if (p1 && p2) {
                                            // check to see if each player's new seed is within acceptable range of original seed
                                            const p1NewSeed = p2.Seed;
                                            const p1MinSeed = p1.InitialSeed - settings.MaxSwapSeedDistance;
                                            const p1maxSeed = p1.InitialSeed + settings.MaxSwapSeedDistance;

                                            if (p1NewSeed < p1MinSeed || p1NewSeed > p1maxSeed) {
                                                continue;
                                            }

                                            const p2NewSeed = p1.Seed;
                                            const p2MinSeed = p2.InitialSeed - settings.MaxSwapSeedDistance;
                                            const p2maxSeed = p2.InitialSeed + settings.MaxSwapSeedDistance;

                                            if (p2NewSeed < p2MinSeed || p2NewSeed > p2maxSeed) {
                                                continue;
                                            }
                                        }

                                        // at this point we want to swap the losers of matchToSwap and matchToReview
                                        const match1Analysis = matchToReview.Analysis;
                                        const match2Analysis = matchToSwap.Analysis;

                                        if (match1Analysis && match2Analysis) {
                                            const match1DaysSinceBeforeSwap = match1Analysis.Value2;
                                            const match2DaysSinceBeforeSwap = match2Analysis.Value2;

                                            // determine the minimum difference before swapping
                                            // we're aiming for higher than this after swapping
                                            let daysSinceMinimumBeforeSwap: null | number = null;

                                            // if there's two numbers then get the minimum
                                            if (match1DaysSinceBeforeSwap && match2DaysSinceBeforeSwap) {
                                                daysSinceMinimumBeforeSwap = Math.min(
                                                    match1DaysSinceBeforeSwap,
                                                    match2DaysSinceBeforeSwap,
                                                );
                                            } else if (match1DaysSinceBeforeSwap) {
                                                daysSinceMinimumBeforeSwap = match1DaysSinceBeforeSwap;
                                            } else if (match2DaysSinceBeforeSwap) {
                                                daysSinceMinimumBeforeSwap = match2DaysSinceBeforeSwap;
                                            }

                                            // now do the same for averages
                                            let daysSinceAverageBeforeSwap: null | number = null;

                                            if (match1DaysSinceBeforeSwap && match2DaysSinceBeforeSwap) {
                                                daysSinceAverageBeforeSwap =
                                                    (match1DaysSinceBeforeSwap + match2DaysSinceBeforeSwap) / 2;
                                            } else if (match1DaysSinceBeforeSwap) {
                                                daysSinceAverageBeforeSwap = match1DaysSinceBeforeSwap;
                                            } else if (match2DaysSinceBeforeSwap) {
                                                daysSinceAverageBeforeSwap = match2DaysSinceBeforeSwap;
                                            }

                                            // now swap the matches
                                            const newMatch1: SeedingMatch = {
                                                ...matchToSwap,
                                                Player2: matchToReview.Player2,
                                            };
                                            const newMatch2: SeedingMatch = {
                                                ...matchToReview,
                                                Player2: matchToSwap.Player2,
                                            };

                                            // run the new analysis
                                            newMatch1.Analysis = analyseMatch(newMatch1);
                                            newMatch2.Analysis = analyseMatch(newMatch2);

                                            const newMatch1DaysSinceAfterSwap = newMatch1.Analysis?.Value2;
                                            const newMatch2DaysSinceAfterSwap = newMatch2.Analysis?.Value2;

                                            // get the new metrics
                                            let daysSinceMinimumAfterSwap: null | number = null;

                                            // minimum
                                            if (newMatch1DaysSinceAfterSwap && newMatch2DaysSinceAfterSwap) {
                                                daysSinceMinimumAfterSwap = Math.min(
                                                    newMatch1DaysSinceAfterSwap,
                                                    newMatch2DaysSinceAfterSwap,
                                                );
                                            } else if (newMatch1DaysSinceAfterSwap) {
                                                daysSinceMinimumAfterSwap = newMatch1DaysSinceAfterSwap;
                                            } else if (newMatch2DaysSinceAfterSwap) {
                                                daysSinceMinimumAfterSwap = newMatch2DaysSinceAfterSwap;
                                            }

                                            // average
                                            let daysSinceAverageAfterSwap: null | number = null;

                                            if (newMatch1DaysSinceAfterSwap && newMatch2DaysSinceAfterSwap) {
                                                daysSinceAverageAfterSwap =
                                                    (newMatch1DaysSinceAfterSwap + newMatch2DaysSinceAfterSwap) / 2;
                                            } else if (newMatch1DaysSinceAfterSwap) {
                                                daysSinceAverageAfterSwap = newMatch1DaysSinceAfterSwap;
                                            } else if (newMatch2DaysSinceAfterSwap) {
                                                daysSinceAverageAfterSwap = newMatch2DaysSinceAfterSwap;
                                            }

                                            // first check if the minimum days have come down
                                            let isMatch = false;

                                            if (daysSinceMinimumAfterSwap && daysSinceMinimumBeforeSwap) {
                                                if (daysSinceMinimumAfterSwap > daysSinceMinimumBeforeSwap) {
                                                    isMatch = true;
                                                }
                                            }

                                            // also check the average if the minimums are the same
                                            if (daysSinceMinimumAfterSwap === daysSinceMinimumBeforeSwap) {
                                                if (daysSinceAverageBeforeSwap && daysSinceAverageAfterSwap) {
                                                    if (daysSinceAverageBeforeSwap < daysSinceAverageAfterSwap) {
                                                        isMatch = true;
                                                    }
                                                }
                                            }

                                            // at this point if the new minimum of the pair of matches is higher
                                            // than the old minimum we should complete the swap process
                                            // this true / false check could become more complex
                                            if (isMatch) {
                                                const suggestedSwapPlayer1 = matchToReview.Player2;
                                                const suggestedSwapPlayer2 = matchToSwap.Player2;

                                                if (suggestedSwapPlayer1 && suggestedSwapPlayer2) {
                                                    // ignore dismissed swaps
                                                    const swapId = generateSuggestionId(
                                                        suggestedSwapPlayer1.InitialSeed,
                                                        suggestedSwapPlayer2.InitialSeed,
                                                        suggestedSwapPlayer1.Seed,
                                                        suggestedSwapPlayer2.Seed,
                                                    );

                                                    if (dismissedSwaps.find(d => d.ID === swapId)) {
                                                        continue;
                                                    }

                                                    const suggestedSwap: SeedingSwapAnalysis = {
                                                        ID: swapId,
                                                        Player1: suggestedSwapPlayer1,
                                                        Player2: suggestedSwapPlayer2,
                                                        Match1: matchToReview,
                                                        Match2: matchToSwap,
                                                        Round: round,
                                                        SuggestedMatch1: newMatch1,
                                                        SuggestedMatch2: newMatch2,
                                                    };

                                                    suggestions.push(suggestedSwap);
                                                }

                                                break;
                                            } else {
                                                // we have analysed both matches and do not want to swap
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                // now that the round is reseeded, add it to the list of reseeded rounds
                reseededRounds.push(round);
            }

            // at this point we have reseeded all of the rounds
            const fixedResult = { ...reseededBracket, Rounds: reseededRounds };
            setReseededBracket(fixedResult);

            // add suggestions
            setSuggestedSwaps(suggestions);

            // automatically add first swap if required
            if (applyAllSuggestions && suggestions.length) {
                swapSeeds(suggestions[0]);
                setAnalysisStatus("RebuildingBracket");
            } else {
                // determine that the analysis is complete
                setAnalysisStatus("BracketAnalysisCompleted");
                setApplyAllSuggestions(false);
            }
        }
    };

    const uploadToSmashGG = (key: string) => {
        if (selectedSmashGGPhase) {
            return postSeedsToSmashGG(selectedSmashGGPhase, playerAnalysisList, key);
        }
    };

    // effects

    useEffect(() => {
        if (findPlayersStatus === "AllFound") {
            // clear out missing player and selected player list

            setSelectedMissingPlayer(undefined);
            setMissingPlayers([]);

            // start loading the match history when all the players have been found
            setAnalysisStatus("SelectingPlayers");
        }

        if (findPlayersStatus === "Importing") {
            // at this point we have imported players we want to try and tag
            importPlayers();
        }

        if (findPlayersStatus === "Reordering") {
            // check to see if we need to reorder players be Elo
            if (reorderByElo) {
                reorderPlayersByElo();
            }

            setFindPlayersStatus("AllFound");
        }
    }, [findPlayersStatus]);

    useEffect(() => {
        // load the next player if we're in the process of loading players
        if (analysisStatus === "LoadingMatchHistory") {
            loadNextPlayerMatchHistory();
        }
    }, [analysisStatus, masterPlayerAnalysisList]);

    // analyse the bracket whenever the analysis status becomes analysis required
    useEffect(() => {
        if (analysisStatus === "BuildingBracket") {
            // at this point make sure it's ordered by seed

            // build the bracket
            const bracket = buildBracket();
            setReseededBracket(bracket);

            // set the initial bracket only the first time
            if (initialBracket == null) {
                setInitialBracket(bracket);
            }

            setAnalysisStatus("OrderingBySeed");
        }

        // reorder the players by seed before loading the matches
        if (analysisStatus === "OrderingBySeed") {
            reorderPlayersBySeed();

            setAnalysisStatus("LoadingMatchHistory");
        }

        if (analysisStatus === "SelectingPlayers") {
            // this step is required to select a subset of analysis for start.gg phases
            // if the phases haven't been determined we shouldn't bother with them
            if (importMethod === "SmashGG" && selectedSmashGGPhase) {
                const phaseId = selectedSmashGGPhase.id;

                // filter out players who don't have seeds for the specified event
                const filteredAnalysis = [
                    ...masterPlayerAnalysisList
                        .filter(
                            // only use players who have a seed for this event
                            a => a.SmashGGSeeds != null && a.SmashGGSeeds.find(s => s.phase.id === phaseId) != null,
                        )
                        .map(a => {
                            // find the player's seed for the selected event
                            const seed = a.SmashGGSeeds?.find(s => s.phase.id === phaseId)?.seedNum ?? a.InitialSeed;

                            return { ...a, Seed: seed, InitialSeed: seed };
                        }),
                ];

                setPlayerAnalysisList(filteredAnalysis);
            } else {
                // if we're not using start.gg then the master list and filtered list are the same
                setPlayerAnalysisList(masterPlayerAnalysisList);
            }

            // now we can build the bracket
            setAnalysisStatus("BuildingBracket");
        }

        if (analysisStatus === "BracketAnalysisRequired") {
            // analyse the bracket now
            findSwapSuggestions();
        }

        if (analysisStatus === "RebuildingBracket") {
            // something has changed, so rebuild the bracket
            const bracket = buildBracket();
            setReseededBracket(bracket);

            if (initialBracket == null) {
                setInitialBracket(bracket);
            }

            // now analyse the now bracket
            setAnalysisStatus("BracketAnalysisRequired");
        }
    }, [analysisStatus]);

    // loop through all swaps to delete all
    useEffect(() => {
        if (undoingAllSwaps) {
            if (swapHistory.length === 0) {
                setUndoingAllSwaps(false);
                setAnalysisStatus("RebuildingBracket");
            } else {
                undoLastSwap();
            }
        }
    }, [undoingAllSwaps, swapHistory, undoLastSwap]);

    // auto select first missing player import
    useEffect(() => {
        if (missingPlayers.length) {
            setSelectedMissingPlayer(missingPlayers[0]);
        }
    }, [missingPlayers]);

    // default missing player text to selected player on select
    useEffect(() => {
        if (selectedMissingPlayer) {
            setMissingPlayerText(selectedMissingPlayer.Name);
        }
    }, [selectedMissingPlayer]);

    // update the list of possible tagging players when the text changes
    useEffect(() => {
        const lower = missingPlayerText.toLowerCase();

        // exclude players who are already selected
        const filteredPlayers = players.filter(x => playerAnalysisList.find(p => p.PlayerID === x.PlayerID) == null);

        // these are the actual options the user can select from
        const options = filteredPlayers.filter(x => {
            // skip if doesn't match text
            if (x.PlayerName.toLowerCase().indexOf(lower) === -1) {
                return false;
            }

            // if there's no selected missing player or region then return all text matched players
            if (selectedRegionShort == null || selectedRegionShort === "") {
                return true;
            }

            // check the region filter
            return x.PlayerRegionShort === selectedRegionShort;
        });

        setMissingPlayerSuggestions(options);
    }, [missingPlayerText, playerAnalysisList, selectedRegionShort]);

    // if we've tagged the last missing player then start the analysis
    useEffect(() => {
        if (findPlayersStatus === "MissingPlayers" && missingPlayers.length === 0) {
            setFindPlayersStatus("Reordering");
        }
    }, [missingPlayers]);

    // auto select the first analysis that becomes available
    useEffect(() => {
        const loadedPlayers = playerAnalysisList.filter(x => x.IsLoaded);

        if (selectedPlayerAnalysis == null && loadedPlayers.length) {
            setSelectedPlayerAnalysis(loadedPlayers[0]);
        }
    }, [playerAnalysisList]);

    // cancel
    useEffect(() => {
        if (isCancelling) {
            reset();
            setIsCancelling(false);
        }
    }, [isCancelling]);

    // reload data when settings have changed
    useEffect(() => {
        setAnalysisStatus("BracketAnalysisRequired");
    }, [settings]);

    // auto select the first phase when a start.gg event is selected
    useEffect(() => {
        if (selectedSmashGGEvent && selectedSmashGGEvent.phases.length) {
            setSelectedSmashGGPhase(selectedSmashGGEvent.phases[0]);
        }
    }, [selectedSmashGGEvent]);

    // when the phase is changed we need to reseed all the players
    useEffect(() => {
        if (analysisStatus === "BracketAnalysisCompleted") {
            // clear out old brackets
            setInitialBracket(undefined);
            setReseededBracket(undefined);

            // select new players
            setAnalysisStatus("SelectingPlayers");
        }
    }, [selectedSmashGGPhase]);

    // upon changed the selected missing player, update the region filter
    useEffect(() => {
        if (selectedMissingPlayer) {
            setSelectedRegionShort(selectedMissingPlayer.Region);
        }
    }, [selectedMissingPlayer]);

    return {
        // state
        settings,
        setSettings,
        selectedGame,
        setSelectedGame,
        games,
        setGames,
        selectedPlayerAnalysis,
        setSelectedPlayerAnalysis,
        regions,
        setRegions,
        swapHistory,
        setSwapHistory,
        initialBracket,
        setInitialBracket,
        playerAnalysisList,
        setPlayerAnalysisList,
        players,
        setPlayers,
        importMethod,
        setImportMethod,
        reseededBracket,
        setReseededBracket,
        suggestedSwaps,
        setSuggestedSwaps,
        selectedBracket,
        setSelectedBracket,
        missingPlayers,
        setMissingPlayers,
        findPlayersStatus,
        setFindPlayersStatus,
        analysisStatus,
        setAnalysisStatus,
        missingPlayerText,
        setMissingPlayerText,
        selectedMissingPlayer,
        setSelectedMissingPlayer,
        missingPlayerSuggestions,
        setMissingPlayerSuggestions,
        importedPlayers,
        setImportedPlayers,
        reorderByElo,
        setReorderByElo,
        dismissedSwaps,
        setDismissedSwaps,
        isCancelling,
        setIsCancelling,
        applyAllSuggestions,
        setApplyAllSuggestions,
        smashGGEvent,
        setSmashGGEvent,
        selectedSmashGGPhase,
        setSelectedSmashGGPhase,
        selectedSmashGGEvent,
        setSelectedSmashGGEvent,
        masterPlayerAnalysisList,
        setMasterPlayerAnalysisList,
        undoingAllSwaps,
        setUndoingAllSwaps,
        selectedRegionShort,
        setSelectedRegionShort,
        smashGGUrl,
        setSmashGGUrl,
        initialLoadFailed,
        setInitialLoadFailed,
        importErrors,
        setImportErrors,

        // methods
        importPlayersFromText,
        importSelectedPlayersFromRegion,
        swapSeeds,
        importSelectedPlayersFromSmashGG,
        reset,
        undoLastSwap,
        tagPlayer,
        addNewPlayer,
        addExistingPlayer,
        uploadToSmashGG,
        savePlayers,
        reorderPlayersByElo,
        generateSuggestionId,
        swapSeedsByNumber,

        service,
    };
};

export type ISeedingContext = ReturnType<typeof useSeedingContext>;
export const SeedingContext = createContext<ISeedingContext>({} as ISeedingContext);

type Props = {
    children: ReactNode;
};

export const SeedingContextProvider = (props: Props) => {
    const seedingContext = useSeedingContext();
    return <SeedingContext.Provider value={seedingContext}>{props.children}</SeedingContext.Provider>;
};

import { useContext } from "react";
import { SeedingContext } from "../../hooks/useSeedingContext";
import { SeedingPlayersLoadingBar } from "./SeedingPlayersLoadingBar";

export const SeedingLoadingMatchHistory = () => {
    const { setIsCancelling, isCancelling } = useContext(SeedingContext);

    return (
        <div className="d-flex flex-column">
            <div className="card mb-3">
                <SeedingPlayersLoadingBar />

                <div className="card-body">
                    <div className="mb-2">
                        <strong>Importing and analysing players</strong>
                    </div>

                    <ul className="mb-0">
                        <li>loading player match histories</li>
                        <li>calculating how recently every player has played each other</li>
                        <li>projecting a bracket</li>
                        <li>identifying problematic matches in each round</li>
                        <li>finding possible seeding swaps</li>
                    </ul>
                </div>
            </div>

            <div>
                <button
                    type="button"
                    disabled={isCancelling}
                    className="btn btn-outline-danger"
                    onClick={() => setIsCancelling(true)}
                >
                    Cancel loading
                </button>
            </div>
        </div>
    );
};

import { createRef, Fragment, useContext, useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from "react-virtualized";
import { AusmashPocketEloItem } from "ausmash-api";
import { SeedingContext } from "../../hooks/useSeedingContext";

export const SeedingMissingPlayerDisplay = () => {
    const {
        selectedMissingPlayer,
        missingPlayerText,
        setMissingPlayerText,
        importMethod,
        missingPlayerSuggestions,
        setMissingPlayers,
        tagPlayer,
        addNewPlayer,
        addExistingPlayer,
        missingPlayers,
        regions,
        playerAnalysisList,
        setPlayerAnalysisList,
        selectedPlayerAnalysis,
        findPlayersStatus,
        setSelectedRegionShort,
        selectedRegionShort,
    } = useContext(SeedingContext);

    const [sortByElo, setSortByElo] = useState(true);
    const [sortedPlayerSuggestions, setSortedPlayerSuggestions] = useState<Array<AusmashPocketEloItem>>([]);
    const [loadingPlayerIds, setLoadingPlayerIds] = useState<Array<number>>([]);

    const listRef = createRef<List>();

    const cache = new CellMeasurerCache({
        fixedWidth: true,
    });

    const addPlayer = (p: AusmashPocketEloItem) => {
        if (selectedMissingPlayer) {
            tagPlayer(p, selectedMissingPlayer.ID);
        } else if (addExistingPlayer) {
            setLoadingPlayerIds(oldList => [...oldList, p.PlayerID]);
            addExistingPlayer(p);
        }
    };

    const tag = (p: AusmashPocketEloItem) => {
        setLoadingPlayerIds(oldList => [...oldList, p.PlayerID]);

        if (selectedPlayerAnalysis) {
            tagPlayer(p, selectedPlayerAnalysis.Seed);
        } else if (selectedMissingPlayer) {
            tagPlayer(p, selectedMissingPlayer.ID);
        }
    };

    // maintain order of list
    useEffect(() => {
        const sorted = [...missingPlayerSuggestions];

        if (sortByElo) {
            sorted.sort((a, b) => (a.Elo == b.Elo ? 0 : a.Elo > b.Elo ? -1 : 1));
        } else {
            sorted.sort((a, b) => (a.PlayerName == b.PlayerName ? 0 : a.PlayerName < b.PlayerName ? -1 : 1));
        }

        // float exact matches to the top
        sorted.sort((a, b) => {
            const lower = missingPlayerText.toLowerCase();

            if (a.PlayerName.toLowerCase() === lower) {
                return -1;
            } else if (b.PlayerName === lower) {
                return 1;
            }

            return 0;
        });

        setSortedPlayerSuggestions(sorted);
    }, [sortByElo, missingPlayerSuggestions, selectedRegionShort]);

    // remove loading player IDs if they're found in the analysis list
    useEffect(() => {
        setLoadingPlayerIds(oldList => [
            ...oldList.filter(x => playerAnalysisList.find(p => p.PlayerID === x) == null),
        ]);
    }, [playerAnalysisList]);

    return (
        <Fragment>
            {(importMethod !== "SmashGG" || findPlayersStatus === "MissingPlayers") && (
                <div className="p-3 bg-light border-bottom border-end">
                    <div className="d-flex justify-content-between">
                        <div>
                            <button
                                type="button"
                                className="btn btn-outline-primary me-2"
                                onClick={addNewPlayer}
                                disabled={missingPlayerText === ""}
                            >
                                Add <strong>{missingPlayerText}</strong> as new player
                            </button>
                        </div>
                        <div>
                            <button
                                type="button"
                                className="btn btn-outline-danger"
                                onClick={() => {
                                    const selected = selectedMissingPlayer;

                                    if (selected) {
                                        const selectedSeed = selected.ID;

                                        // remove from list
                                        let s = 0;
                                        const newList = [
                                            ...playerAnalysisList
                                                .filter(x => x.InitialSeed !== selectedSeed) // remove current player
                                                .map(p => {
                                                    s++;
                                                    return {
                                                        ...p,
                                                        Seed: s,
                                                        InitialSeed: s,
                                                    };
                                                }),
                                        ];

                                        // save list
                                        setPlayerAnalysisList(newList);
                                    }

                                    // remove from missing player
                                    setMissingPlayers([
                                        ...missingPlayers.filter(x => x.ID !== selectedMissingPlayer?.ID),
                                    ]);
                                }}
                            >
                                Remove player
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <div className="p-3 bg-light border-bottom border-end">
                <div className="mb-3">
                    <strong>Tag player from Ausmash</strong>
                </div>

                <div className="row">
                    <div className="col">
                        <input
                            type="search"
                            className="form-control"
                            value={missingPlayerText}
                            onChange={e => setMissingPlayerText(e.target.value)}
                        />
                    </div>
                    <div className="col">
                        <select
                            className="form-select mb-3"
                            id="Region"
                            value={selectedRegionShort}
                            onChange={e => setSelectedRegionShort(e.target.value)}
                        >
                            <option value="">All regions</option>

                            {regions.map(r => (
                                <option key={r.ID} value={r.Short}>
                                    {r.Name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="form-check form-check-inline">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="rb"
                        id="rb1"
                        checked={sortByElo}
                        onChange={() => setSortByElo(true)}
                    />
                    <label className="form-check-label" htmlFor="rb1">
                        Sort players by Elo
                    </label>
                </div>
                <div className="form-check form-check-inline">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="rb"
                        id="rb2"
                        checked={!sortByElo}
                        onChange={() => setSortByElo(false)}
                    />
                    <label className="form-check-label" htmlFor="rb2">
                        Sort players by name
                    </label>
                </div>
            </div>

            <div className="d-flex flex-grow-1 flex-column border-end">
                {sortedPlayerSuggestions.length === 0 && (
                    <div className="alert alert-warning">
                        No players found for <strong>{missingPlayerText}</strong>
                    </div>
                )}

                <div className="d-flex h-100 w-100">
                    <AutoSizer>
                        {({ height, width }) => (
                            <List
                                ref={listRef}
                                height={height}
                                width={width}
                                deferredMeasurementCache={cache}
                                rowCount={sortedPlayerSuggestions.length}
                                rowHeight={cache.rowHeight}
                                rowRenderer={e => {
                                    const p = sortedPlayerSuggestions[e.index];

                                    return (
                                        <CellMeasurer cache={cache} key={e.key} parent={e.parent} rowIndex={e.index}>
                                            <div
                                                style={e.style}
                                                className="list-group-item list-group-item-action d-flex justify-content-between"
                                            >
                                                {loadingPlayerIds.find(x => x === p.PlayerID) != null ? (
                                                    <Fragment>
                                                        <div className="d-flex justify-content-center flex-grow-1">
                                                            <div className="spinner spinner-border spinner-border-sm"></div>
                                                        </div>
                                                    </Fragment>
                                                ) : (
                                                    <Fragment>
                                                        <div className="d-flex justify-content-between flex-grow-1 pe-3">
                                                            <div>
                                                                <Highlighter
                                                                    highlightClassName="fw-bold p-0"
                                                                    searchWords={[missingPlayerText]}
                                                                    autoEscape={true}
                                                                    textToHighlight={p.PlayerName}
                                                                />
                                                            </div>
                                                            <div className="ps-2">{p.PlayerRegionShort}</div>
                                                        </div>
                                                        <div>
                                                            {selectedMissingPlayer == null && (
                                                                <button
                                                                    onClick={() => addPlayer(p)}
                                                                    disabled={
                                                                        loadingPlayerIds.length > 0 ||
                                                                        importMethod === "SmashGG"
                                                                    }
                                                                    type="button"
                                                                    className="btn btn-sm btn-outline-secondary"
                                                                >
                                                                    Add
                                                                </button>
                                                            )}
                                                            <button
                                                                type="button"
                                                                onClick={() => tag(p)}
                                                                disabled={
                                                                    selectedPlayerAnalysis?.PlayerID != null &&
                                                                    selectedMissingPlayer == null
                                                                }
                                                                className="btn btn-outline-secondary btn-sm ms-2"
                                                            >
                                                                Tag
                                                            </button>
                                                        </div>
                                                    </Fragment>
                                                )}
                                            </div>
                                        </CellMeasurer>
                                    );
                                }}
                            />
                        )}
                    </AutoSizer>
                </div>
            </div>
        </Fragment>
    );
};

import { useContext } from "react";
import { SeedingContext } from "../../hooks/useSeedingContext";

export const SeedingPlayersLoadingBar = () => {
    const { playerAnalysisList } = useContext(SeedingContext);

    return (
        <div className="flex-grow-1">
            <div className="progress">
                <div
                    className="progress-bar progress-bar-striped progress-bar-animated"
                    style={{
                        width: `${
                            (playerAnalysisList.filter(x => x.IsLoaded).length * 100) / playerAnalysisList.length
                        }%`,
                    }}
                ></div>
            </div>
        </div>
    );
};

import clsx from "clsx";
import { PropsWithChildren, FC, useEffect, CSSProperties } from "react";

type Props = {
    auth?: "public" | "private";
    anchorTop?: boolean;
    className?: string;
    style?: CSSProperties;
};

export const Page: FC<Props> = (props: PropsWithChildren<Props>) => {
    const { anchorTop, className, children, style } = props;

    useEffect(() => {
        // scroll page to top on route change
        window.scroll(0, 0);
    }, []);

    return (
        <div style={style} className={clsx("trans", anchorTop && "trans--top")}>
            <div className={clsx("page", className)}>{children}</div>
        </div>
    );
};

Page.defaultProps = {
    auth: "private",
    anchorTop: false,
};

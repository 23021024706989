import { PropsWithChildren } from "react";
import { CSSTransition } from "react-transition-group";
import { Constants } from "../../utils/Constants";

export const ListItemTransition = (props: PropsWithChildren<any>) => {
    const { children } = props;

    const { animationDuration } = Constants;

    return (
        <CSSTransition timeout={animationDuration} classNames="animation-item">
            {children}
        </CSSTransition>
    );
};

import { useContext } from "react";
import { Shuffle } from "react-bootstrap-icons";
import { SeedingContext } from "../../hooks/useSeedingContext";
import { ModalHeader } from "../modals/ModalHeader";

export const SeedingDismissedSwapsModal = () => {
    const { dismissedSwaps, setDismissedSwaps, setAnalysisStatus } = useContext(SeedingContext);

    return (
        <div className="modal fade" id="DismissedSwapsModal">
            <div className="modal-dialog">
                <div className="modal-content">
                    <ModalHeader name="Dismissed swaps" />

                    <div className="modal-body">
                        <div className="list-group">
                            {dismissedSwaps.map(s => (
                                <div
                                    key={s.ID}
                                    className="d-flex justify-content-between list-group-item list-group-item-action"
                                >
                                    <div>
                                        <span className="text-primary me-2">
                                            <strong>{s.Player1.Seed}</strong> <Shuffle />{" "}
                                            <strong>{s.Player2.Seed}</strong>
                                        </span>
                                        <strong>{s.Player1.PlayerName}</strong> and{" "}
                                        <strong>{s.Player2.PlayerName}</strong>{" "}
                                    </div>
                                    <div className="d-flex align-items-start">
                                        <button
                                            type="button"
                                            className="btn btn-inline"
                                            onClick={() => {
                                                setDismissedSwaps([...dismissedSwaps.filter(x => x != s)]);
                                                setAnalysisStatus("BracketAnalysisRequired");
                                            }}
                                        >
                                            Undo
                                        </button>
                                    </div>
                                </div>
                            ))}

                            {dismissedSwaps.length === 0 && (
                                <div className="alert alert-warning mb-0">There are no dismissed swaps to display.</div>
                            )}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

import clsx from "clsx";
import { useContext } from "react";
import { SeedingContext } from "../../hooks/useSeedingContext";
import { SeedingWeeksAgo } from "./SeedingWeeksAgo";

export const SeedingPlayerAnalysisDisplay = () => {
    const { selectedPlayerAnalysis } = useContext(SeedingContext);

    return (
        <div className="list-group overflow-y-scroll border-end flex-grow-1">
            {selectedPlayerAnalysis?.Opponents.filter(x => x.PlayerID != null).map(o => (
                <div key={o.PlayerID} className={"list-group-item d-flex justify-content-between"}>
                    <span className={clsx(o.DaysSinceLastPlayed == null && "text-success")}>{o.PlayerName}</span>
                    <span className="ms-3">
                        <SeedingWeeksAgo weeksAgo={o.WeeksSinceLastPlayed} daysAgo={o.DaysSinceLastPlayed} />
                    </span>
                </div>
            ))}
        </div>
    );
};

import { useState } from "react";

export enum LoadingState {
    Initial = 1 << 0,
    Loading = 1 << 1,
    Success = 1 << 2,
    Error = 1 << 3,
}

export const useLoadingState = (defaultLoading = false) => {
    const [loadingState, setLoadingState] = useState<LoadingState>(
        defaultLoading ? LoadingState.Loading : LoadingState.Initial,
    );

    const isLoadingState = (s: LoadingState) => (loadingState & s) === s;

    return {
        isLoadingState,
        setLoadingState,
    };
};

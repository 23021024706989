import { SeedingPlayerAnalysis } from "../interfaces/seeding/SeedingInterfaces";
import { SmashGGEventRequest, SmashGGPhase, SmashGGSeed } from "../interfaces/smashgg/SmashGGTournament";

const eventByIdQuery = (id: number) => `
    query please {
        event( id: ${id}){
        name,
        slug,
        }
    }
`;

export interface SmashGGError {
    message: string;
}

const callApi = <T,>(query: string, key?: string) => {
    const headers = new Headers();

    const token = `Bearer ${key ?? "8c2f1754a145694eb01da52fcd56350e"}`; // 32

    if (token) {
        headers.append("authorization", token);
        headers.append("content-type", "application/json");
    }

    return fetch("https://api.start.gg/gql/alpha", {
        headers: headers,
        method: "POST",
        body: JSON.stringify({ query: query, operationName: "please" }),
    }).then(async x => {
        const result: SmashGGApiResult<T> = {
            success: x.ok,
        };

        if (result.success) {
            // success
            result.result = (await x.json()) as T;
        } else {
            // failure
            result.errors = (await x.json()) as SmashGGError;
        }

        return result;
    });
};

export const parseSmashGGEventUrl = async (url: string) => {
    if (url.indexOf("/admin/") > -1) {
        // get id from admin url
        // https://www.start.gg/admin/tournament/super-shiba-bros/event-reg/555955
        const [, , , , , , , id] = url.split("/");

        // allow pasting of admin URLs
        const r = await callApi<SmashGGEventRequest>(eventByIdQuery(+id)).then(data => {
            if (data.result) {
                const [, tourney, , tourneyEvent] = data.result.data.event.slug.split("/");

                const result = { tourney, tourneyEvent, eventSlug: data.result.data.event.slug };

                return result;
            }
        });

        if (r) {
            return r;
        }
    }

    const [, , , , tourney, , tourneyEvent] = url.split("/");

    const eventSlug = `tournament/${tourney}/event/${tourneyEvent}`;

    return { tourney, tourneyEvent, eventSlug };
};

export interface SmashGGApiResult<T> {
    success: boolean;
    result?: T;
    errors?: SmashGGError;
}

export const postSeedsToSmashGG = (phase: SmashGGPhase, seeds: Array<SeedingPlayerAnalysis>, key: string) => {
    let s = `mutation please ($phaseId: ID = ${phase.id}, $seedMapping: [UpdatePhaseSeedInfo] = [`;

    const seedList: Array<SmashGGSeed> = [];

    for (const a of seeds) {
        const seedData = a.SmashGGSeeds?.find(x => x.phase.id === phase.id);

        if (seedData) {
            // update the seed number here
            seedData.seedNum = a.Seed;
            seedList.push(seedData);
        }
    }

    // the start.gg import needs to have seeds in the order of seedNum
    seedList.sort((a, b) => (a.seedNum > b.seedNum ? 1 : -1));

    for (const a of seedList) {
        // find seed in seed list
        s += `{ seedId: ${a.id}, seedNum: ${a.seedNum} },`;
    }

    s += `]) {
        updatePhaseSeeding (phaseId: $phaseId, seedMapping: $seedMapping) {
          id
        }
      }`;

    return callApi(s, key);
};

const tournamentQuery = (eventSlug: string, page: number) => `query please {
    event(slug: "${eventSlug}") {
        id,
        name,
        slug,
        phases {
            id,
            name,
        },
        entrants(query: { perPage: 100, page: ${page} }) {
            nodes {
                seeds {
                    seedNum,
                    id,
                    phase {
                        id,
                        name,
                    }
                },
                participants {
                    player {
                        id,
                        gamerTag,
                        user {
                            location {
                                state
                            }
                        }
                    }
                }
            }
        }
    }
}`;

export const getSmashGGEvent = async (url: string) => {
    const { eventSlug } = await parseSmashGGEventUrl(url);

    let searching = true;
    let page = 1;

    // get the first page of the request
    const result = await callApi<SmashGGEventRequest>(tournamentQuery(eventSlug, page));

    page++;

    if (result.result) {
        while (searching) {
            const nextResult = await callApi<SmashGGEventRequest>(tournamentQuery(eventSlug, page));
            let foundResult = false;

            const e = nextResult.result?.data.event;

            if (e) {
                if (e.entrants.nodes.length > 0) {
                    foundResult = true;

                    for (const node of e.entrants.nodes) {
                        result.result.data.event.entrants.nodes.push(node);
                    }
                }
            } else {
                // if the tournament is null for any reason stop
                searching = false;
            }

            if (foundResult) {
                // if we've found any results go to the next page
                page++;
            } else {
                searching = false;
            }
        }
    }

    return result;
};

import * as yup from "yup";

export interface SeedingImportFromSmashGGModel {
    url: string;
}

export const SeedingImportFromSmashGGModelSchema = yup.object().shape({
    url: yup
        .string()
        .required("Please enter the start.gg event URL")
        .matches(
            /^(https:\/\/(www.)?start.gg\/tournament\/[a-z0-9-]+\/event\/[a-z-0-9-/]+|https:\/\/(www.)?start.gg\/admin\/tournament\/[a-z-].*\/[a-z-].*\/[0-9].*)$/,
            {
                message: "e.g. https://www.start.gg/admin/tournament/super-shiba-bros/event-edit/555955",
            },
        ),
});

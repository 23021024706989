import * as yup from "yup";

export interface SeedingImportFromTextModel {
    file: FileList;
}

export const SeedingImportFromTextModelSchema = yup.object().shape({
    file: yup
        .mixed()
        .test("file", "Please upload a .txt file", (a: FileList) => {
            if (a.length > 0) {
                return true;
            }

            return false;
        })
        .test("file", "File must be a .txt file", (a: FileList) => {
            const [file] = a;

            if (file) {
                const ext = file.name.split(".").pop();

                if (ext && ext.toLowerCase() === "txt") {
                    return true;
                }

                return false;
            }

            return true;
        }),
});

import { Fragment, useContext } from "react";
import { SeedingContext } from "../../hooks/useSeedingContext";
import { pluralise } from "../../utils/Language";
import { ComponentTransition } from "../animation/ComponentTransition";
import { SeedingNoAnalysis } from "./SeedingNoAnalysis";
import { SeedingSwapAnalysisDisplay } from "./SeedingSwapAnalysisDisplay";

export const SeedingSuggestedSwapList = () => {
    const { settings, suggestedSwaps, setApplyAllSuggestions, swapSeeds, dismissedSwaps } = useContext(SeedingContext);

    const applyAllSwaps = () => {
        setApplyAllSuggestions(true);
        swapSeeds(suggestedSwaps[0]);
    };

    return (
        <Fragment>
            <div className="d-flex flex-column justify-content-between bg-light border-end p-3 border-bottom">
                <div className="d-flex justify-content-between">
                    <div>
                        <div>
                            Only swap players a maximum of{" "}
                            <strong>{pluralise(settings.MaxSwapSeedDistance, "seed")}</strong>{" "}
                        </div>
                        <div>
                            Find players who have played since <strong>{settings.DangerZone.toDateString()}</strong>
                        </div>
                    </div>

                    <div>
                        <button
                            type="button"
                            className="btn btn-outline-secondary ms-3 btn-sm"
                            data-bs-toggle="modal"
                            data-bs-target="#SettingsModal"
                        >
                            Settings
                        </button>
                    </div>
                </div>
            </div>

            <div className="d-flex flex-column flex-grow-1 overflow-y-scroll">
                <div className="mt-3 mx-3 d-flex justify-content-between">
                    <div className="me-3">
                        Identified <strong>{pluralise(suggestedSwaps.length, "swap")}</strong>:
                    </div>
                    <div>
                        <button
                            disabled={suggestedSwaps.length === 0}
                            type="button"
                            className="btn btn-primary btn-sm"
                            onClick={applyAllSwaps}
                        >
                            Apply all swaps
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary btn-sm ms-2"
                            data-bs-toggle="modal"
                            data-bs-target="#ManualSwap"
                        >
                            Perform manual swap
                        </button>
                        <button
                            type="button"
                            disabled={dismissedSwaps.length === 0}
                            className="btn btn-outline-secondary btn-sm ms-2"
                            data-bs-toggle="modal"
                            data-bs-target="#DismissedSwapsModal"
                        >
                            View dismissed swaps
                        </button>
                    </div>
                </div>

                <div className="d-flex">
                    <ComponentTransition show={suggestedSwaps.length > 0} classNames="flex-column">
                        {suggestedSwaps.map(suggestion => (
                            <SeedingSwapAnalysisDisplay suggestion={suggestion} key={suggestion.ID} />
                        ))}
                    </ComponentTransition>

                    <ComponentTransition show={suggestedSwaps.length === 0}>
                        <SeedingNoAnalysis />
                    </ComponentTransition>
                </div>
            </div>
        </Fragment>
    );
};

import { useContext } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    SeedingImportFromSmashGGModel,
    SeedingImportFromSmashGGModelSchema,
} from "../../models/seeding/SeedingImportFromSmashGGModel";
import { SeedingContext } from "../../hooks/useSeedingContext";

export const SeedingImportFromSmashGG = () => {
    const { importSelectedPlayersFromSmashGG } = useContext(SeedingContext);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<SeedingImportFromSmashGGModel>({
        resolver: yupResolver(SeedingImportFromSmashGGModelSchema),
    });

    const onSubmit = handleSubmit(model => {
        importSelectedPlayersFromSmashGG(model.url);
    });

    const importExample = () => {
        importSelectedPlayersFromSmashGG(
            "https://www.start.gg/tournament/friday-night-smash-254/event/smash-ultimate-singles/overview",
        );
    };

    return (
        <form
            onSubmit={onSubmit}
            className="ms-3 flex-grow-1 position-static justify-content-end d-flex flex-column border-start px-3"
        >
            <div className="d-flex justify-content-between">
                <label htmlFor="SmashGG" className="form-label">
                    Import from start.gg
                </label>
                <div>
                    <button type="button" className="mb-2 btn btn-inline fw-normal ms-3" onClick={importExample}>
                        Import FNS 254
                    </button>
                </div>
            </div>

            <div className="d-flex">
                <input
                    type="text"
                    id="SmashGG"
                    className="form-control"
                    placeholder="https://www.start.gg/admin/tournament/super-shiba-bros/event-edit/555955"
                    {...register("url")}
                />

                <button className="btn btn-primary ms-2">Import</button>
            </div>

            {errors.url && (
                <div className="bg-danger text-white position-absolute p-2 top-100">{errors.url.message}</div>
            )}
        </form>
    );
};

import { useContext } from "react";
import { SeedingContext } from "../../hooks/useSeedingContext";
import { SeedingPlayerButton } from "./SeedingPlayerButton";

export const SeedingPlayersList = () => {
    const { playerAnalysisList, analysisStatus } = useContext(SeedingContext);

    return (
        <div className="d-flex flex-grow-1 flex-column overflow-y-scroll border-end">
            <div className="d-flex">
                <div className="list-group flex-grow-1">
                    {playerAnalysisList
                        .filter(x => x.IsLoaded)
                        .sort((a, b) => (a.Seed > b.Seed ? 1 : -1))
                        .map(a => (
                            <SeedingPlayerButton btn={a} key={a.InitialSeed} />
                        ))}
                </div>
            </div>

            {analysisStatus === "LoadingMatchHistory" && (
                <div className="d-flex justify-content-center pt-3">
                    <div className="spinner-grow spinner-grow-sm"></div>
                </div>
            )}
        </div>
    );
};

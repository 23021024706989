import { Fragment, useContext, useEffect, useState } from "react";
import { downloadText } from "../../utils/Download";
import { SeedingContext } from "../../hooks/useSeedingContext";

export const SeedingHeaderOptions = () => {
    const {
        reset,
        playerAnalysisList,
        setMissingPlayerSuggestions,
        setSelectedRegionShort,
        setInitialBracket,
        findPlayersStatus,
        setPlayerAnalysisList,
        setSwapHistory,
        setAnalysisStatus,
        players,
        analysisStatus,
        savePlayers,
        setMissingPlayerText,
        setFindPlayersStatus,
    } = useContext(SeedingContext);

    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

    // when the list of players updates, check to see if the save button should be enabled
    useEffect(() => {
        for (const p of playerAnalysisList) {
            // if any player doesn't have their original seed then there's a change
            if (p.InitialSeed !== p.Seed) {
                setSaveButtonDisabled(false);
                return;
            }
        }

        setSaveButtonDisabled(true);
    }, [playerAnalysisList]);

    const download = () => {
        // generate text
        let s = "";

        for (const p of playerAnalysisList) {
            if (p.RegionShort) {
                s += `${p.RegionShort} `;
            }

            s += `${p.PlayerName}\r\n`;
        }

        downloadText(s, "AusmashSeeding.txt");
    };

    return (
        <div className="d-flex ms-5">
            {analysisStatus === "BracketAnalysisCompleted" && (
                <Fragment>
                    {findPlayersStatus === "Editing" ? (
                        <button
                            type="button"
                            className="btn btn-primary me-2"
                            onClick={() => {
                                // manually update all seeds
                                const newList = [...playerAnalysisList];

                                let i = 1;

                                for (const p of newList) {
                                    p.InitialSeed = i;
                                    p.Seed = i;
                                    i++;
                                }

                                setPlayerAnalysisList(newList);

                                setFindPlayersStatus("AllFound");
                                setSwapHistory([]);
                                setInitialBracket(undefined);
                                setAnalysisStatus("RebuildingBracket");
                            }}
                        >
                            Confirm new seeding
                        </button>
                    ) : (
                        <Fragment>
                            <button
                                disabled={saveButtonDisabled}
                                type="button"
                                className="btn btn-primary me-2"
                                onClick={savePlayers}
                            >
                                Save current swaps
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary me-2"
                                onClick={() => {
                                    // load all the players to show
                                    setMissingPlayerSuggestions(players);
                                    setMissingPlayerText("");
                                    setSelectedRegionShort(undefined);

                                    // clear old initial bracket
                                    setInitialBracket(undefined);

                                    // order player analysis by initial seed
                                    setPlayerAnalysisList(oldList => [
                                        ...oldList.sort((a, b) => (a.InitialSeed > b.InitialSeed ? 1 : -1)),
                                    ]);

                                    // toggle state
                                    setFindPlayersStatus("Editing");
                                }}
                            >
                                Change original seeding
                            </button>
                        </Fragment>
                    )}

                    <button type="button" className="btn btn-outline-secondary me-2" onClick={download}>
                        Download bracket
                    </button>
                </Fragment>
            )}
            <button type="button" className="btn btn-outline-danger" onClick={reset}>
                Restart
            </button>
        </div>
    );
};

import { useContext } from "react";
import { ComponentTransition } from "../animation/ComponentTransition";
import { SeedingFindPlayers } from "./SeedingFindPlayers";
import { SeedingLoadingMatchHistory } from "./SeedingLoadingMatchHistory";
import { SeedingPlayerAnalysisDisplay } from "./SeedingPlayerAnalysisDisplay";
import { SeedingPlayersList } from "./SeedingPlayersList";
import { SeedingResultDisplay } from "./SeedingResultDisplay";
import { SeedingSuggestedSwapList } from "./SeedingSuggestedSwapList";
import { SeedingSwapHistory } from "./SeedingSwapHistory";
import { SeedingMissingPlayerDisplay } from "./SeedingMissingPlayerDisplay";
import { SeedingColumnHeader } from "./SeedingColumnHeader";
import { SeedingEditPlayersList } from "./SeedingEditPlayersList";
import { SeedingContext } from "../../hooks/useSeedingContext";

export const SeedingProcess = () => {
    const {
        selectedPlayerAnalysis,
        initialBracket,
        swapHistory,
        selectedBracket,
        setSelectedBracket,
        reseededBracket,
        findPlayersStatus,
        analysisStatus,
        importMethod,
        selectedMissingPlayer,
        playerAnalysisList,
        setPlayerAnalysisList,
        setSelectedPlayerAnalysis,
    } = useContext(SeedingContext);

    const deletePlayer = () => {
        const selected = selectedPlayerAnalysis;

        if (selected) {
            const selectedSeed = selected.InitialSeed;

            // remove from list
            let s = 0;
            const newList = [
                ...playerAnalysisList
                    .filter(x => x.InitialSeed !== selectedSeed) // remove current player
                    .map(p => {
                        s++;
                        return {
                            ...p,
                            Seed: s,
                            InitialSeed: s,
                            Opponents: p.Opponents.filter(x => x.PlayerID !== selected.PlayerID),
                        };
                    }),
            ];

            // save list
            setPlayerAnalysisList(newList);

            // update selected player
            setSelectedPlayerAnalysis(newList[selectedSeed - 1]);
        }
    };

    const untagPlayer = () => {
        const selected = selectedPlayerAnalysis;

        if (selected) {
            // remove from list
            const newList = [
                ...playerAnalysisList.map(p => {
                    if (p.InitialSeed === selected.InitialSeed) {
                        const item = {
                            ...selected,
                            PlayerID: undefined,
                            Elo: 0,
                            Opponents: [
                                ...p.Opponents.map(x => ({ ...x, WeeksSinceLastPlayed: undefined, TimesPlayed: 0 })),
                            ],
                        };

                        setSelectedPlayerAnalysis(item);

                        return item;
                    }

                    return {
                        ...p,
                        Opponents: p.Opponents.filter(x => x.PlayerID !== selected.PlayerID),
                    };
                }),
            ];

            // save list
            setPlayerAnalysisList(newList);
        }
    };

    return (
        <div className="d-flex flex-grow-1">
            <ComponentTransition show={findPlayersStatus === "MissingPlayers"}>
                <div className="d-flex flex-grow-1">
                    <div className="d-flex">
                        <div className="d-flex flex-column flex-shrink-0 min-width-250 flex-grow-1">
                            <SeedingColumnHeader name="Missing players" />

                            <SeedingFindPlayers />
                        </div>

                        <div className="d-flex flex-column flex-shrink-0 min-width-350 flex-grow-1">
                            <SeedingColumnHeader name={`${selectedMissingPlayer?.Name}`} />

                            <SeedingMissingPlayerDisplay />
                        </div>
                    </div>
                </div>
            </ComponentTransition>

            <ComponentTransition show={findPlayersStatus === "Editing"}>
                <div className="d-flex flex-grow-1">
                    <div className="d-flex flex-column flex-shrink-0 min-width-250">
                        <SeedingColumnHeader name="Players" />

                        <SeedingEditPlayersList />
                    </div>

                    <div className="d-flex flex-column flex-shrink-0 min-width-250">
                        <SeedingColumnHeader
                            name={
                                selectedPlayerAnalysis == null
                                    ? "No player selected"
                                    : `${selectedPlayerAnalysis.PlayerName} last played`
                            }
                        />

                        <div className="p-3 border-bottom border-end bg-light">
                            <div>
                                <button
                                    disabled={importMethod === "SmashGG"}
                                    type="button"
                                    className="btn btn-outline-danger"
                                    onClick={deletePlayer}
                                >
                                    Delete {selectedPlayerAnalysis?.PlayerName}
                                </button>
                            </div>

                            <div>
                                <button
                                    type="button"
                                    disabled={selectedPlayerAnalysis?.PlayerID == null}
                                    className="btn btn-outline-secondary mt-2"
                                    onClick={untagPlayer}
                                >
                                    Untag {selectedPlayerAnalysis?.PlayerName}
                                </button>
                            </div>
                        </div>

                        <SeedingPlayerAnalysisDisplay />
                    </div>

                    <div className="d-flex flex-column flex-shrink-0 min-width-250">
                        <SeedingColumnHeader name="Tag players" />

                        <SeedingMissingPlayerDisplay />
                    </div>

                    {importMethod === "SmashGG" && findPlayersStatus === "Editing" && (
                        <div className="d-flex flex-column flex-grow-1">
                            <SeedingColumnHeader name="Editing start.gg players" />

                            <div className="d-flex align-items-start justify-content-start p-3 flex-grow-1">
                                <div className="max-width-300">
                                    <div className="alert alert-warning">
                                        <p>You cannot add or remove players if you have imported from start.gg</p>
                                        <p className="mb-0">
                                            This is because it ensures you can upload the players back to start.gg.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </ComponentTransition>

            <ComponentTransition show={findPlayersStatus === "AllFound"}>
                <div className="d-flex flex-grow-1">
                    <div className="d-flex">
                        <div className="d-flex flex-column flex-shrink-0 min-width-250 flex-grow-1">
                            <SeedingColumnHeader name="Players" />

                            <SeedingPlayersList />
                        </div>

                        <div className="d-flex flex-column flex-shrink-0 min-width-250 flex-grow-1">
                            <SeedingColumnHeader
                                name={
                                    selectedPlayerAnalysis == null
                                        ? "No player selected"
                                        : `${selectedPlayerAnalysis.PlayerName} last played`
                                }
                            />

                            <SeedingPlayerAnalysisDisplay />
                        </div>

                        <div className="d-flex flex-column flex-shrink-0 min-width-350 flex-grow-1">
                            <SeedingColumnHeader name="Bracket" />

                            <div className="bg-light p-3 border-end border-bottom">
                                <div className="btn-group">
                                    <input
                                        type="checkbox"
                                        className="btn-check"
                                        disabled={swapHistory.length === 0}
                                        checked={swapHistory.length === 0 || selectedBracket === "original"}
                                        onChange={() => setSelectedBracket("original")}
                                        id="rb1"
                                    />
                                    <label htmlFor="rb1" className="btn btn-outline-secondary">
                                        Original
                                    </label>
                                    <input
                                        type="checkbox"
                                        className="btn-check"
                                        checked={swapHistory.length > 0 && selectedBracket === "reseeded"}
                                        onChange={() => setSelectedBracket("reseeded")}
                                        disabled={swapHistory.length === 0}
                                        id="rb2"
                                    />
                                    <label htmlFor="rb2" className="btn btn-outline-secondary">
                                        Reseeded
                                    </label>
                                </div>
                            </div>

                            <div className="overflow-y-scroll d-flex flex-grow-1 flex-column border-end">
                                {selectedBracket === "original" && initialBracket && (
                                    <SeedingResultDisplay result={initialBracket} />
                                )}

                                {selectedBracket === "reseeded" && reseededBracket && reseededBracket && (
                                    <SeedingResultDisplay result={reseededBracket} />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="d-flex flex-grow-1">
                        <ComponentTransition show={analysisStatus === "LoadingMatchHistory"} classNames="flex-column">
                            <SeedingColumnHeader name="Loading players" />

                            <div className="d-flex align-items-start justify-content-start p-3 flex-grow-1">
                                <SeedingLoadingMatchHistory />
                            </div>
                        </ComponentTransition>

                        <ComponentTransition show={analysisStatus === "BracketAnalysisCompleted"}>
                            {!initialBracket != null && (
                                <div className="d-flex flex-grow-1 flex-shrink-0">
                                    <div className="d-flex flex-column flex-grow-1 flex-shrink-0 min-width-550">
                                        <SeedingColumnHeader name="Analysis results" />

                                        <SeedingSuggestedSwapList />
                                    </div>

                                    <div className="d-flex flex-column flex-shrink-0 flex-grow-0 min-width-350">
                                        <SeedingColumnHeader name="Swap history" />

                                        <SeedingSwapHistory />
                                    </div>
                                </div>
                            )}
                        </ComponentTransition>
                    </div>
                </div>
            </ComponentTransition>
        </div>
    );
};

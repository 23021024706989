import { useContext } from "react";
import { SeedingContext } from "../../hooks/useSeedingContext";
import { SeedingImportFromSmashGG } from "./SeedingImportFromSmashGG";
import { SeedingImportFromText } from "./SeedingImportFromText";

export const SeedingImportOptions = () => {
    const { reorderByElo, setReorderByElo } = useContext(SeedingContext);

    return (
        <div className="d-flex justify-content-start flex-grow-1 border-start text-nowrap">
            <div className="ms-3 justify-content-end d-flex flex-column">
                <div className="form-label">Import order</div>
                <div className="d-flex">
                    <div className="btn-group">
                        <input
                            type="radio"
                            className="btn-check"
                            checked={!reorderByElo}
                            onChange={() => setReorderByElo(r => !r)}
                            id="ReorderByEloFalse"
                        />
                        <label htmlFor="ReorderByEloFalse" className="btn btn-outline-secondary">
                            Original
                        </label>
                        <input
                            type="radio"
                            className="btn-check"
                            checked={reorderByElo}
                            onChange={() => setReorderByElo(r => !r)}
                            id="ReorderByEloTrue"
                        />
                        <label htmlFor="ReorderByEloTrue" className="btn btn-outline-secondary">
                            Elo
                        </label>
                    </div>
                </div>
            </div>

            <SeedingImportFromSmashGG />

            <SeedingImportFromText />
        </div>
    );
};

import { Fragment } from "react";
import { SeedingMatchAnalysis } from "../../interfaces/seeding/SeedingInterfaces";
import { SeedingWeeksAgo } from "./SeedingWeeksAgo";

type Props = {
    match?: SeedingMatchAnalysis;
};

export const SeedingPlayed = (props: Props) => {
    const { match } = props;

    if (match && (match.Value || match.Value2)) {
        return (
            <Fragment>
                played <SeedingWeeksAgo weeksAgo={match.Value} daysAgo={match.Value2} />
            </Fragment>
        );
    }

    return <span className="text-success">never played</span>;
};

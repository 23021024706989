import { SeedingPage } from "./pages/SeedingPage";
import { HomePage } from "./pages/HomePage";

export const RouteList = {
    Seeding: "/seeding",
    Home: "/",
};

export const RouteTable = [
    { path: RouteList.Seeding, Component: SeedingPage },
    { path: RouteList.Home, Component: HomePage },
];

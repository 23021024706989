import clsx from "clsx";
import moment from "moment";
import { useContext } from "react";
import { SeedingContext } from "../../hooks/useSeedingContext";
import { pluralise } from "../../utils/Language";
import { SeedingPlayed } from "./SeedingPlayed";

type Props = {
    weeksAgo?: number;
    daysAgo?: number;
};

export const SeedingWeeksAgo = (props: Props) => {
    const { weeksAgo, daysAgo } = props;
    const { settings } = useContext(SeedingContext);

    const isDanger = daysAgo && settings.DangerZone < moment().add(-daysAgo, "days").toDate();

    if (weeksAgo == null || daysAgo == null) {
        return <SeedingPlayed />;
    }

    return (
        <span className={clsx(isDanger && "text-danger")}>
            {weeksAgo == null
                ? ""
                : isDanger
                ? daysAgo && `${pluralise(daysAgo, "day")} ago`
                : weeksAgo === 0
                ? "last week"
                : `${pluralise(weeksAgo, "week")} ago`}
        </span>
    );
};

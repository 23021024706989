type Props = {
    name: string;
};

export const ModalHeader = (props: Props) => {
    const { name } = props;

    return (
        <div className="modal-header">
            <h5 className="modal-title">{name}</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
        </div>
    );
};

import { useContext } from "react";
import { SeedingContext } from "../../hooks/useSeedingContext";

export const SeedingSmashGGPhaseSelector = () => {
    const { selectedSmashGGEvent, analysisStatus, setSelectedSmashGGPhase } = useContext(SeedingContext);

    return (
        <div className="d-flex align-items-end">
            <div>
                <label className="form-label">start.gg phase</label>
                <select
                    className="form-select"
                    disabled={analysisStatus !== "BracketAnalysisCompleted"}
                    onChange={e => {
                        // update the selected phase when the select list changed
                        const phaseId = +e.target.value;

                        if (selectedSmashGGEvent) {
                            const phase = selectedSmashGGEvent.phases.find(x => x.id === phaseId);

                            if (phase) {
                                setSelectedSmashGGPhase(phase);
                            }
                        }
                    }}
                >
                    {selectedSmashGGEvent?.phases.map(p => (
                        <option key={p.id} value={p.id}>
                            {p.name}
                        </option>
                    ))}
                </select>
            </div>
            <div>
                <button
                    type="button"
                    className="btn btn-primary ms-3"
                    data-bs-toggle="modal"
                    data-bs-target="#SmashGGModal"
                >
                    Upload to start.gg
                </button>
            </div>
        </div>
    );
};

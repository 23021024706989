import { useContext } from "react";
import { Shuffle } from "react-bootstrap-icons";
import { SeedingContext } from "../../hooks/useSeedingContext";

export const SeedingSwapHistory = () => {
    const { swapHistory, undoLastSwap, setUndoingAllSwaps, setAnalysisStatus } = useContext(SeedingContext);

    const undo = () => {
        undoLastSwap();
        setAnalysisStatus("RebuildingBracket");
    };

    return (
        <div className="d-flex flex-column overflow-y-scroll">
            <div className="d-flex justify-content-center pt-3 pb-3 mb-3">
                <button
                    type="button"
                    className="btn btn-outline-danger"
                    disabled={swapHistory.length === 0}
                    onClick={() => setUndoingAllSwaps(true)}
                >
                    Undo all swaps
                </button>

                <button
                    type="button"
                    className="btn btn-outline-secondary ms-2"
                    disabled={swapHistory.length === 0}
                    onClick={undo}
                >
                    Undo last swap
                </button>
            </div>

            <div className="list-group">
                {swapHistory.map(h => (
                    <div className="list-group-item d-flex justify-content-between" key={h.ID}>
                        <span>
                            Swapped <strong>{h.Player1.PlayerName}</strong> and <strong>{h.Player2.PlayerName}</strong>
                        </span>
                        <span className="text-primary ms-3">
                            <strong>{h.Player1.Seed}</strong> <Shuffle /> <strong>{h.Player2.Seed}</strong>
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

import { useContext } from "react";
import { SeedingContext } from "../../hooks/useSeedingContext";

export const SeedingGameSelector = () => {
    const { setSelectedGame: setSelectedGame, selectedGame: selectedGame, games } = useContext(SeedingContext);

    return (
        <div className="justify-content-end d-flex flex-column border-start ps-3">
            <div className="form-label">Game</div>
            <button className="btn btn-outline-secondary dropdown-toggle me-3" type="button" data-bs-toggle="dropdown">
                {selectedGame ? selectedGame.Short : "Select game"}
            </button>
            <ul className="dropdown-menu">
                {games?.map(g => (
                    <li key={g.ID}>
                        <button type="button" className="dropdown-item" onClick={() => setSelectedGame(g)}>
                            {g.Name}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

import { Fragment, useContext, useEffect } from "react";
import { ComponentTransition } from "../animation/ComponentTransition";
import { SeedingGameSelector } from "./SeedingGameSelector";
import { SeedingSmashGGPhaseSelector } from "./SeedingSmashGGPhaseSelector";
import { SeedingImportOptions } from "./SeedingImportOptions";
import { SeedingHeaderOptions } from "./SeedingHeaderOptions";
import Logo from "../../img/logo-192.png";
import { SeedingContext } from "../../hooks/useSeedingContext";

export const SeedingHeader = () => {
    const {
        setPlayers,
        games,
        selectedGame,
        setSelectedGame,
        importMethod,
        setGames,
        setRegions,
        findPlayersStatus,
        setFindPlayersStatus,
        reset,
        setInitialLoadFailed,
        initialLoadFailed,
        analysisStatus,
        selectedSmashGGPhase,
        service,
    } = useContext(SeedingContext);

    // get the games and region for the select list on page load
    useEffect(() => {
        const p1 = service.Pocket.getGames()
            .then(data => {
                setGames(data);
            })
            .catch(() => {
                setInitialLoadFailed(true);
            });

        const p2 = service.Regions.getRegions()
            .then(data => {
                setRegions(data);
            })
            .catch(() => {
                setInitialLoadFailed(true);
            });

        Promise.all([p1, p2]).then(() => {
            setFindPlayersStatus("Initial");
        });
    }, []);

    // auto select game on load
    useEffect(() => {
        if (selectedGame == null && games.length) {
            setSelectedGame(games[0]);
        }
    }, [games]);

    useEffect(() => {
        // clear any previous data
        reset();

        // load available players into memory up selecting a game
        if (selectedGame) {
            service.Pocket.getElo(selectedGame.ID).then(data => {
                setPlayers(data);
            });
        }
    }, [selectedGame]);

    return (
        <Fragment>
            <h1 className="me-3 mb-0 text-nowrap d-flex align-items-center">
                <div>
                    <img alt="Ausmash Desktop" src={Logo} className="header-logo" />
                </div>
                <div className="d-flex flex-column">
                    <div>Ausmash Auto Seeder</div>
                    <small className="text-muted h5">Make better seeding decisions</small>
                </div>
            </h1>

            <div className="position-relative flex-grow-1">
                <ComponentTransition
                    show={findPlayersStatus === "Loading"}
                    classNames="align-items-center justify-content-start ps-3"
                >
                    <div className="spinner spinner-border"></div>
                </ComponentTransition>

                <ComponentTransition
                    show={findPlayersStatus !== "Loading" && initialLoadFailed}
                    classNames="align-items-center"
                >
                    <div>
                        <div className="alert alert-danger mb-0">Failed to connect to Ausmash.</div>
                    </div>
                </ComponentTransition>

                <ComponentTransition
                    show={findPlayersStatus !== "Loading" && games.length > 0 && importMethod == "None"}
                >
                    <SeedingGameSelector />

                    <div className="position-relative flex-grow-1">
                        <ComponentTransition show={importMethod === "None"}>
                            <SeedingImportOptions />
                        </ComponentTransition>
                    </div>
                </ComponentTransition>

                <ComponentTransition
                    show={findPlayersStatus !== "Loading" && analysisStatus === "BracketAnalysisCompleted"}
                >
                    {selectedSmashGGPhase != null ? (
                        <div>
                            <SeedingSmashGGPhaseSelector />
                        </div>
                    ) : (
                        importMethod === "SmashGG" && (
                            <div className="d-flex align-items-center">
                                <div className="alert alert-secondary mb-0">
                                    To upload seeds to start.gg you will first need to create phases in start.gg.
                                </div>
                            </div>
                        )
                    )}

                    <div className="d-flex flex-grow-1 justify-content-end align-items-center">
                        <SeedingHeaderOptions />
                    </div>
                </ComponentTransition>

                <ComponentTransition
                    classNames="justify-content-end align-items-center"
                    show={
                        (findPlayersStatus !== "Loading" && findPlayersStatus === "MissingPlayers") ||
                        analysisStatus === "LoadingMatchHistory"
                    }
                >
                    <div>
                        <SeedingHeaderOptions />
                    </div>
                </ComponentTransition>
            </div>
        </Fragment>
    );
};

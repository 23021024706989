import { useContext } from "react";
import { SeedingContext } from "../../hooks/useSeedingContext";
import { pluralise } from "../../utils/Language";
import { SeedingWeeksAgo } from "./SeedingWeeksAgo";

export const SeedingNoAnalysis = () => {
    const { settings, setSettings } = useContext(SeedingContext);

    return (
        <div className="pt-3 px-3">
            <div className="alert alert-success w-100">
                <div>
                    You may find more swaps if you are willing to swap players up to{" "}
                    <strong>{settings.MaxSwapSeedDistance + 1} places</strong>
                </div>
            </div>

            <button
                type="button"
                className="btn btn-outline-primary me-2"
                onClick={() => {
                    setSettings(oldSettings => ({
                        ...oldSettings,
                        MaxSwapSeedDistance: oldSettings.MaxSwapSeedDistance + 1,
                    }));
                }}
            >
                Find swaps {pluralise(settings.MaxSwapSeedDistance + 1, "rank")} apart
            </button>

            <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#SettingsModal"
                className="btn btn-outline-secondary"
            >
                Change date filter
            </button>
        </div>
    );
};

import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { RouteList } from "../Routes";
import { Page } from "./Page";

export const HomePage = () => {
    // default to seeding
    const history = useHistory();

    useEffect(() => {
        history.push(RouteList.Seeding);
    }, []);

    return <Page></Page>;
};

import clsx from "clsx";
import { PropsWithChildren } from "react";
import { CSSTransition } from "react-transition-group";
import { Constants } from "../../utils/Constants";

type Props = {
    show: boolean;
    classNames?: string | Array<string>;
};

export const ComponentTransition = (props: PropsWithChildren<Props>) => {
    const { show, children, classNames } = props;
    const { animationDuration } = Constants;

    return (
        <CSSTransition classNames="trans" in={show} timeout={animationDuration} unmountOnExit>
            <div className={clsx("comp-trans", classNames)}>{children}</div>
        </CSSTransition>
    );
};

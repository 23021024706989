import clsx from "clsx";
import { useContext } from "react";
import { TransitionGroup } from "react-transition-group";
import { SeedingContext } from "../../hooks/useSeedingContext";
import { SeedingBracket } from "../../interfaces/seeding/SeedingInterfaces";
import { ComponentTransition } from "../animation/ComponentTransition";
import { ListItemTransition } from "../animation/ListItemTransition";
import { SeedingWeeksAgo } from "./SeedingWeeksAgo";

type Props = {
    result: SeedingBracket;
};

export const SeedingResultDisplay = (props: Props) => {
    const { result } = props;

    const { selectedPlayerAnalysis } = useContext(SeedingContext);

    return (
        <TransitionGroup className="list-group">
            {result.Rounds.map(r => (
                <ListItemTransition key={r.RoundNumber}>
                    <div className="list-group-item">
                        <div className="mb-1 d-flex justify-content-between">
                            <strong>Round {r.RoundNumber}</strong>
                            <strong>Last played</strong>
                        </div>

                        {r.Matches.sort((a, b) => {
                            if (a.MatchID > b.MatchID) {
                                return 1;
                            } else if (a.MatchID < b.MatchID) {
                                return -1;
                            }

                            return 0;
                        }).map(m => {
                            const selectedPlayerId = selectedPlayerAnalysis?.PlayerID;
                            const p1Selected = selectedPlayerId && m.Player1?.PlayerID == selectedPlayerId;
                            const p2Selected = selectedPlayerId && m.Player2?.PlayerID == selectedPlayerId;

                            return (
                                <div key={m.MatchID} className="d-flex justify-content-between">
                                    <div className="text-nowrap flex-grow-1">
                                        <strong>{m.MatchID}. </strong>
                                        <span className={clsx(p1Selected && "fw-bold text-primary")}>
                                            {m.Player1?.PlayerName ?? "BYE"}
                                        </span>
                                        {" - "}
                                        <span className={clsx(p2Selected && "fw-bold text-primary")}>
                                            {m.Player2?.PlayerName ?? "BYE"}
                                        </span>
                                    </div>
                                    <div className="ms-3 flex-grow-1 justify-content-end">
                                        <ComponentTransition
                                            show={m.Analysis?.Value != null && m.Analysis?.Value2 != null}
                                            classNames="justify-content-end"
                                        >
                                            <small className="text-nowrap">
                                                <SeedingWeeksAgo
                                                    weeksAgo={m.Analysis?.Value}
                                                    daysAgo={m.Analysis?.Value2}
                                                />
                                            </small>
                                        </ComponentTransition>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </ListItemTransition>
            ))}
        </TransitionGroup>
    );
};

import { useContext } from "react";
import { useForm } from "react-hook-form";
import { SeedingContext } from "../../hooks/useSeedingContext";
import { downloadText } from "../../utils/Download";
import {
    SeedingImportFromTextModel,
    SeedingImportFromTextModelSchema,
} from "../../models/seeding/SeedingImportFromTextModel";
import { yupResolver } from "@hookform/resolvers/yup";

export const SeedingImportFromText = () => {
    const { importPlayersFromText } = useContext(SeedingContext);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<SeedingImportFromTextModel>({
        resolver: yupResolver(SeedingImportFromTextModelSchema),
    });

    // file upload
    const fileReader = new FileReader();

    fileReader.onloadend = () => {
        const content = fileReader.result;

        if (content) {
            importPlayersFromText(content.toString());
        }
    };

    const downloadExample = () => {
        const s = "VIC Extra\r\nQLD Shadrew\r\nSadhour\r\nNewPlayer\r\nAnother new player\r\n";

        downloadText(s, "AusmashSeeding.txt");
    };

    const onSubmit = handleSubmit(model => {
        const fileList = model.file;

        if (fileList && fileList.length) {
            const [file] = fileList;

            fileReader.readAsText(file);
        }
    });

    return (
        <form onSubmit={onSubmit} className="position-static justify-content-end d-flex flex-column ps-3 border-start">
            <div>
                <div className="form-label d-flex justify-content-between align-items-end">
                    <label htmlFor="File">Import from text file</label>

                    <div>
                        <button
                            type="button"
                            className="btn btn-inline fw-normal ms-3"
                            onClick={() => downloadExample()}
                        >
                            See example
                        </button>
                    </div>
                </div>
                <div className="d-flex">
                    <input type="file" id="File" className="form-control" {...register("file")} />
                    <button className="btn btn-primary ms-2">Import</button>
                </div>
            </div>

            {errors.file && (
                <div className="bg-danger text-white position-absolute p-2 top-100 right-0">{errors.file.message}</div>
            )}
        </form>
    );
};

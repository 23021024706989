import { useEffect } from "react";
import "./scss/app.scss";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { RouteTable } from "./Routes";
import { CSSTransition } from "react-transition-group";
import { Constants } from "./utils/Constants";
import Logo from "./img/logo-large.png";
import "bootstrap";

export const App = () => {
    const { animationDuration } = Constants;

    useEffect(() => {
        // redraw on resize to fix items not be vertically centered when screen sizes change
        window.addEventListener("resize", () => {
            document.body.style.display = "inline-block";

            setTimeout(() => {
                document.body.style.display = "";
            }, 1);
        });

        // set CSS animation variable here so it's referenced in on place in JS
        document.documentElement.style.setProperty("--animation-duration", `${animationDuration}ms`);
    }, [animationDuration]);

    return (
        <Router>
            <div className="d-lg-none d-flex flex-column flex-grow-1 align-items-center justify-content-center p-3 text-center">
                <p>
                    <img alt="Ausmsah" src={Logo} />
                </p>

                <h2>Ausmash Desktop</h2>

                <p className="mb-4">
                    This site is not designed to be used on smaller screens such as tablet or mobiles.
                </p>

                <div>
                    <a href="https://ausmash.com.au" className="btn btn-primary">
                        Go to Ausmash
                    </a>
                </div>
            </div>
            <div className="d-lg-flex d-none pages">
                {RouteTable.map(({ path, Component }) => (
                    <Route exact path={path} key={path}>
                        {({ match }) => (
                            <CSSTransition
                                classNames="trans"
                                in={match != null}
                                timeout={animationDuration}
                                unmountOnExit
                            >
                                <Component {...match?.params} />
                            </CSSTransition>
                        )}
                    </Route>
                ))}
            </div>
        </Router>
    );
};

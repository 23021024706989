import * as yup from "yup";

export interface SeedingUploadToSmashGGModel {
    key: string;
}

export const SeedingUploadToSmashGGModelSchema = yup.object().shape({
    key: yup
        .string()
        .required("Please enter a developer key")
        .matches(/^[a-f0-9]{32}$/, {
            message: "Must be a 32 letter start.gg developer key",
        }),
});

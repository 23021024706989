import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { getSmashGGApiKey, setSmashGGApiKey } from "../../services/LocalService";
import { ModalHeader } from "../modals/ModalHeader";
import { LoadingState, useLoadingState } from "../../hooks/useLoadingState";
import {
    SeedingUploadToSmashGGModel,
    SeedingUploadToSmashGGModelSchema,
} from "../../models/seeding/SeedingUploadToSmashGGModel";
import { SeedingContext } from "../../hooks/useSeedingContext";

export const SeedingUploadToSmashGGModal = () => {
    const { selectedSmashGGPhase, uploadToSmashGG, smashGGUrl } = useContext(SeedingContext);

    const { isLoadingState, setLoadingState } = useLoadingState();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<SeedingUploadToSmashGGModel>({
        resolver: yupResolver(SeedingUploadToSmashGGModelSchema),
        defaultValues: {
            key: getSmashGGApiKey(),
        },
    });

    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (modalRef.current) {
            modalRef.current.addEventListener("hidden.bs.modal", () => {
                setLoadingState(LoadingState.Initial);
            });
        }
    }, []);

    const onSubmit = handleSubmit(({ key }) => {
        setLoadingState(LoadingState.Loading);

        // save api key for later
        setSmashGGApiKey(key);

        // upload to smash gg
        uploadToSmashGG(key)?.then(x => {
            if (x.success) {
                setLoadingState(LoadingState.Success);
            } else if (x.errors) {
                setLoadingState(LoadingState.Error);
            }
        });
    });

    return (
        <div className="modal fade" id="SmashGGModal" ref={modalRef}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <form onSubmit={onSubmit}>
                        <ModalHeader name={`Upload ${selectedSmashGGPhase?.name} to start.gg`} />

                        <div className="modal-body">
                            {isLoadingState(LoadingState.Initial) && (
                                <div>
                                    <label className="form-label">start.gg API key</label>
                                    <input type="text" className="form-control" {...register("key")} />
                                    <div className="form-text">
                                        To upload your results to start.gg go to <strong>Developer settings</strong>,
                                        create a new token and paste the token in here.
                                    </div>
                                    {errors.key && <div className="text-danger">{errors.key.message}</div>}
                                </div>
                            )}

                            {isLoadingState(LoadingState.Loading) && (
                                <div className="d-flex justify-content-center">
                                    <div className="spinner spinner-border"></div>
                                </div>
                            )}

                            {isLoadingState(LoadingState.Error) && (
                                <div className="alert alert-danger mb-0">There was an error uploading to start.gg.</div>
                            )}

                            {isLoadingState(LoadingState.Success) && (
                                <div className="alert alert-success mb-0">Seeding uploaded to start.gg</div>
                            )}
                        </div>
                        <div className="modal-footer">
                            {isLoadingState(LoadingState.Initial) && (
                                <button className="btn btn-primary">Upload</button>
                            )}

                            {isLoadingState(LoadingState.Success) && (
                                <a target="_blank" className="btn btn-secondary" href={smashGGUrl} rel="noreferrer">
                                    View start.gg event
                                </a>
                            )}

                            <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">
                                Close
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

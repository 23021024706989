import { useContext, useEffect, useRef, useState } from "react";
import { ModalHeader } from "../modals/ModalHeader";
import { SeedingPlayerAnalysis, SeedingSwapAnalysis } from "../../interfaces/seeding/SeedingInterfaces";
import { SeedingContext } from "../../hooks/useSeedingContext";

export const SeedingManualSwapModal = () => {
    const {
        playerAnalysisList,
        swapSeedsByNumber,
        setSwapHistory,
        generateSuggestionId,
        setAnalysisStatus,
    } = useContext(SeedingContext);

    const [player1Seed, setPlayer1Seed] = useState<number>();
    const [player2Seed, setPlayer2Seed] = useState<number>();
    const [player1Options, setPlayer1Options] = useState<Array<SeedingPlayerAnalysis>>([]);
    const [player2Options, setPlayer2Options] = useState<Array<SeedingPlayerAnalysis>>([]);

    const modalRef = useRef<HTMLDivElement>(null);

    const updateSelectListData = () => {
        setPlayer1Options([...playerAnalysisList.filter(x => x.Seed !== player2Seed)]);
        setPlayer2Options([...playerAnalysisList.filter(x => x.Seed !== player1Seed)]);
    };

    const swap = () => {
        if (player1Seed && player2Seed) {
            const p1 = playerAnalysisList.find(x => x.Seed === player1Seed);
            const p2 = playerAnalysisList.find(x => x.Seed === player2Seed);

            if (p1 && p2) {
                // perform swap
                swapSeedsByNumber(p1.Seed, p2.Seed);

                // build suggestion
                const suggestion: SeedingSwapAnalysis = {
                    ID: generateSuggestionId(p1.InitialSeed, p2.InitialSeed, p1.Seed, p2.Seed),
                    Player1: p1,
                    Player2: p2,
                };

                // add to history
                setSwapHistory(oldList => [...oldList, suggestion]);

                // trigger bracket rebuild
                setAnalysisStatus("RebuildingBracket");
            }
        }
    };

    useEffect(() => {
        setPlayer1Options(playerAnalysisList);
        setPlayer2Options(playerAnalysisList);

        if (modalRef.current) {
            modalRef.current.addEventListener("hidden.bs.modal", () => {
                setPlayer2Seed(-2);
                setPlayer1Seed(-1);
            });
        }
    }, []);

    useEffect(() => {
        updateSelectListData();
    }, [player1Seed, player2Seed, playerAnalysisList]);

    return (
        <div className="modal fade" id="ManualSwap" ref={modalRef}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <ModalHeader name="Manually swap players" />

                    <div className="modal-body">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="Player1Seed">
                                Player 1
                            </label>

                            <select
                                onChange={e => {
                                    setPlayer1Seed(+e.target.value);
                                    updateSelectListData();
                                }}
                                className="form-select"
                                id="Player1Seed"
                                value={player1Seed}
                            >
                                <option value="-1">Select player 1</option>
                                {player1Options.map(p => (
                                    <option key={p.Seed} value={p.Seed}>
                                        {p.Seed} - {p.PlayerName}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <label className="form-label" htmlFor="Player2Seed">
                                Player 2
                            </label>

                            <select
                                disabled={player1Seed == null || player1Seed < 0}
                                className="form-select"
                                onChange={e => {
                                    setPlayer2Seed(+e.target.value);
                                    updateSelectListData();
                                }}
                                id="Player2Seed"
                                value={player2Seed}
                            >
                                <option value="-2">Select player 2</option>
                                {player2Options.map(p => (
                                    <option key={p.Seed} value={p.Seed}>
                                        {p.Seed} - {p.PlayerName}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button
                            type="button"
                            data-bs-dismiss="modal"
                            disabled={player1Seed == null || player2Seed == null || player1Seed < 0 || player2Seed < 0}
                            className="btn btn-primary"
                            onClick={swap}
                        >
                            Swap
                        </button>

                        <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

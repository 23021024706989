import smashgg from "../../img/smashgg.png";
import ausmash from "../../img/logo-large.png";
import seeding1 from "../../img/seeding1.png";
import seeding2 from "../../img/seeding2.png";
import seeding3 from "../../img/seeding3.png";
import { useState, useRef, useEffect, useContext } from "react";
import { SeedingContext } from "../../hooks/useSeedingContext";

interface BeforeInstallPromptEvent extends Event {
    readonly platforms: Array<string>;
    readonly userChoice: Promise<{
        outcome: "accepted" | "dismissed";
        platform: string;
    }>;
    prompt(): Promise<void>;
}

export const SeedingIntroduction = () => {
    const [showInstall, setShowInstall] = useState(false);

    const { regions, importSelectedPlayersFromRegion } = useContext(SeedingContext);

    const deferredPrompt = useRef<BeforeInstallPromptEvent>();

    const selectPlayersForRegion = (regionId?: number) => {
        importSelectedPlayersFromRegion(regionId);
    };

    // this is all used to show a custom install button on Chrome only
    useEffect(() => {
        window.addEventListener("beforeinstallprompt", e => {
            e.preventDefault();

            if ((e as BeforeInstallPromptEvent) != null) {
                deferredPrompt.current = e as BeforeInstallPromptEvent;
            }

            setShowInstall(true);
        });

        // this fires on install PWA, even if it's done manually
        window.addEventListener("appinstalled", () => {
            // Log install to analytics
            setShowInstall(false);
        });

        // this determines on load if it's operating as an app or in the browser
        window.addEventListener("DOMContentLoaded", () => {
            if ((navigator as any).standalone) {
                setShowInstall(false);
            }
            if (window.matchMedia("(display-mode: standalone)").matches) {
                setShowInstall(false);
            }
        });
    }, []);

    const install = () => {
        if (deferredPrompt.current) {
            deferredPrompt.current.prompt();

            // Wait for the user to respond to the prompt
            deferredPrompt.current.userChoice.then(choiceResult => {
                if (choiceResult.outcome === "accepted") {
                    window.location.reload();
                }
            });
        }
    };

    return (
        <div className="p-3 d-flex">
            <div className="container ms-0">
                <div className="mb-3 d-flex justify-content-between">
                    <div className="d-inline-flex flex-column">
                        <div className="d-flex align-items-center mb-4">
                            <div className="me-5">
                                <img alt="Ausmash" src={ausmash} />
                            </div>
                            <div className="max-width-250">
                                <img alt="start.gg" src={smashgg} className="img-fluid" />
                            </div>
                        </div>
                        <div>
                            <h2 className="mb-2">Seed your tournaments with Ausmash data</h2>
                        </div>

                        <blockquote className="blockquote mb-3">
                            Because nobody likes having to play the same opponents every week.
                        </blockquote>
                    </div>
                    <div>
                        {showInstall && (
                            <button type="button" className="btn btn-primary ms-4" onClick={install}>
                                Install to desktop
                            </button>
                        )}
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-4">
                        <div className="">
                            <strong className="mb-3 d-block h3">1. Import</strong>

                            <div className="fs-5">Add players directly from start.gg or a text file.</div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="">
                            <strong className="mb-3 d-block h3">2. Reseed</strong>

                            <div className="fs-5">Automatically identify and swap recent opponents.</div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="">
                            <strong className="mb-3 d-block h3">3. Upload</strong>

                            <div className="fs-5">Easily publish your reseeded bracket up to to start.gg.</div>
                        </div>
                    </div>
                </div>
                <div className="mb-3 row">
                    <div className="col-12">
                        <p className="blockquote fw-bold">
                            Use live data
                            <br />
                            Run great tournaments
                        </p>
                    </div>
                </div>

                {regions.length > 0 && (
                    <div className="justify-content-end d-flex flex-column mb-3">
                        <div className="form-label">Test with top players from:</div>
                        <div>
                            <button
                                type="button"
                                className="btn btn-primary dropdown-toggle me-3"
                                data-bs-toggle="dropdown"
                            >
                                Select region
                            </button>
                            <ul className="dropdown-menu">
                                {regions.map(r => (
                                    <li key={r.ID}>
                                        <button
                                            type="button"
                                            className="dropdown-item"
                                            onClick={() => selectPlayersForRegion(r.ID)}
                                        >
                                            {r.Name}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}
            </div>

            <div className="d-flex flex-column ps-3">
                <div className="container">
                    <div
                        id="carouselExampleSlidesOnly"
                        className="carousel slide carousel-fade border shadow"
                        data-bs-ride="carousel"
                        data-bs-interval="7000"
                    >
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img className="img-fluid" src={seeding1} alt="" />
                            </div>
                            <div className="carousel-item">
                                <img className="img-fluid" src={seeding2} alt="" />
                            </div>
                            <div className="carousel-item">
                                <img className="img-fluid" src={seeding3} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

import clsx from "clsx";
import { useContext } from "react";
import { SeedingContext } from "../../hooks/useSeedingContext";

export const SeedingFindPlayers = () => {
    const { missingPlayers, setSelectedMissingPlayer, selectedMissingPlayer } = useContext(SeedingContext);

    return (
        <div className="list-group overflow-y-scroll border-end flex-grow-1">
            {missingPlayers.map(p => (
                <button
                    key={p.ID}
                    type="button"
                    className={clsx(
                        "list-group-item",
                        "list-group-item-action",
                        "d-flex",
                        "justify-content-between",
                        selectedMissingPlayer?.ID === p.ID && "active",
                    )}
                    onClick={() => setSelectedMissingPlayer(p)}
                >
                    <div>{p.Name}</div>
                    <div className="ps-2">{p.Region}</div>
                </button>
            ))}
        </div>
    );
};

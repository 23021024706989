import { useContext } from "react";
import { Shuffle } from "react-bootstrap-icons";
import { SeedingContext } from "../../hooks/useSeedingContext";
import { SeedingPlayed } from "./SeedingPlayed";
import { SeedingSwapAnalysis } from "../../interfaces/seeding/SeedingInterfaces";

type Props = {
    suggestion: SeedingSwapAnalysis;
};

export const SeedingSwapAnalysisDisplay = (props: Props) => {
    const { suggestion } = props;

    const { setSuggestedSwaps, setSelectedPlayerAnalysis, setDismissedSwaps, dismissedSwaps, swapSeeds } = useContext(
        SeedingContext,
    );

    const dismiss = () => {
        // remove from suggested swaps
        setSuggestedSwaps(oldSwaps => [...oldSwaps.filter(x => x.ID !== suggestion.ID)]);

        // add to the ignore list
        setDismissedSwaps([...dismissedSwaps, suggestion]);
    };

    return (
        <div className="pt-3 px-3">
            <div className="card">
                <div className="d-flex flex-column mb-3">
                    <div className="card-header justify-content-between d-flex">
                        <strong className="text-nowrap">
                            Consider swapping{" "}
                            <button
                                type="button"
                                className="btn-inline"
                                onClick={() => setSelectedPlayerAnalysis(suggestion.Player1)}
                            >
                                {suggestion.Player1.PlayerName}
                            </button>{" "}
                            and{" "}
                            <button
                                type="button"
                                className="btn-inline"
                                onClick={() => setSelectedPlayerAnalysis(suggestion.Player2)}
                            >
                                {suggestion.Player2.PlayerName}
                            </button>
                        </strong>
                        <div className="ms-3">
                            <span className="text-primary">
                                <strong>{suggestion.Player1.Seed}</strong> <Shuffle />{" "}
                                <strong>{suggestion.Player2.Seed}</strong>
                            </span>
                        </div>
                    </div>
                    <div className="card-body">
                        <p>
                            With the current players in <strong>round {suggestion.Round?.RoundNumber}</strong>:
                        </p>
                        <ul>
                            <li>
                                <strong>{suggestion.Match1?.Player1?.PlayerName}</strong> and{" "}
                                <strong>{suggestion.Match1?.Player2?.PlayerName}</strong> have{" "}
                                <SeedingPlayed match={suggestion.Match1?.Analysis} />.
                            </li>
                            <li>
                                <strong>{suggestion.Match2?.Player1?.PlayerName}</strong> and{" "}
                                <strong>{suggestion.Match2?.Player2?.PlayerName}</strong> have{" "}
                                <SeedingPlayed match={suggestion.Match2?.Analysis} />.
                            </li>
                        </ul>
                        <p>
                            If you swap {suggestion.Player1.PlayerName} and {suggestion.Player2.PlayerName} then the new
                            pairings will be:
                        </p>
                        <ul className="mb-0">
                            <li>
                                <strong>{suggestion.SuggestedMatch1?.Player1?.PlayerName}</strong> and{" "}
                                <strong>{suggestion.SuggestedMatch1?.Player2?.PlayerName}</strong> who have{" "}
                                <SeedingPlayed match={suggestion.SuggestedMatch1?.Analysis} />.
                            </li>
                            <li>
                                <strong>{suggestion.SuggestedMatch2?.Player1?.PlayerName}</strong> and{" "}
                                <strong>{suggestion.SuggestedMatch2?.Player2?.PlayerName}</strong> who have{" "}
                                <SeedingPlayed match={suggestion.SuggestedMatch2?.Analysis} />.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="card-footer">
                    <button type="button" className="btn btn-primary" onClick={() => swapSeeds(suggestion)}>
                        Swap seeds
                    </button>

                    <button type="button" className="btn btn-outline-danger ms-2" onClick={() => dismiss()}>
                        Dismiss
                    </button>
                </div>
            </div>
        </div>
    );
};

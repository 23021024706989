import moment from "moment";
import { useContext } from "react";
import { SeedingContext } from "../../hooks/useSeedingContext";
import { pluralise } from "../../utils/Language";
import { ModalHeader } from "../modals/ModalHeader";

export const SeedingSettingsModal = () => {
    const { settings, setSettings } = useContext(SeedingContext);

    return (
        <div className="modal fade" id="SettingsModal">
            <div className="modal-dialog">
                <div className="modal-content">
                    <ModalHeader name="Settings" />

                    <div className="modal-body">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="MaxSwapSeedDistance">
                                Maximum swap seed distance
                            </label>
                            <input
                                id="MaxSwapSeedDistance"
                                value={settings.MaxSwapSeedDistance}
                                min="1"
                                onChange={e => {
                                    setSettings(oldSettings => ({
                                        ...oldSettings,
                                        MaxSwapSeedDistance: +e.target.value,
                                    }));
                                }}
                                type="number"
                                className="form-control"
                            />
                            <div className="form-text">
                                Only recommend swaps for players who are{" "}
                                <strong>{pluralise(settings.MaxSwapSeedDistance, "place")}</strong> apart in seeding
                            </div>
                        </div>
                        <div>
                            <label className="form-label" htmlFor="DangerZone">
                                Danger zone
                            </label>
                            <input
                                id="DangerZone"
                                type="date"
                                max={moment().format("YYYY-MM-DD")}
                                value={moment(settings.DangerZone).format("YYYY-MM-DD")}
                                onChange={e => {
                                    setSettings(oldSettings => ({
                                        ...oldSettings,
                                        DangerZone: moment(e.target.value).toDate(),
                                    }));
                                }}
                                className="form-control"
                            />
                            <div className="form-text">
                                Find opponents who have played each other since{" "}
                                <strong>{settings.DangerZone.toDateString()}</strong>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

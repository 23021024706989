import clsx from "clsx";

type Props = {
    name: string;
    bg?: "primary" | "secondary";
};

export const SeedingColumnHeader = (props: Props) => {
    const { name, bg } = props;

    const classes = [];

    if (bg === "primary") {
        classes.push("bg-secondary");
        classes.push("text-white");
    }

    if (bg === "secondary") {
        classes.push("bg-secondary");
        classes.push("text-white");
    }

    return (
        <div className={clsx("card-header", "border-end", classes)}>
            <strong>{name}</strong>
        </div>
    );
};

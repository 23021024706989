import { Toast } from "bootstrap";
import { useRef, useEffect } from "react";

type Props = {
    text: string;
};

export const CustomToast = (props: Props) => {
    const { text } = props;

    const toastRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (toastRef.current && text) {
            const toast = new Toast(toastRef.current, { delay: 3000 });

            toast.show();
        }
    }, [text]);

    return (
        <div ref={toastRef} className="toast bg-danger text-white">
            <div className="toast-body">{text}</div>
        </div>
    );
};

import { useContext } from "react";
import { Page } from "./Page";
import { SeedingContext, SeedingContextProvider } from "../hooks/useSeedingContext";
import { SeedingIntroduction } from "../components/seeding/SeedingIntroduction";
import { ComponentTransition } from "../components/animation/ComponentTransition";
import { SeedingProcess } from "../components/seeding/SeedingProcess";
import { SeedingHeader } from "../components/seeding/SeedingHeader";
import { SeedingSettingsModal } from "../components/seeding/SeedingSettingsModal";
import { SeedingUploadToSmashGGModal } from "../components/seeding/SeedingUploadToSmashGGModal";
import { SeedingDismissedSwapsModal } from "../components/seeding/SeedingDismissedSwapsModal";
import { SeedingManualSwapModal } from "../components/seeding/SeedingManualSwapModal";
import { CustomToast } from "../components/CustomToast";
import { setTitle } from "../utils/Title";

const SeedingPageContent = () => {
    const { importMethod, importErrors } = useContext(SeedingContext);

    setTitle("Auto Seeder");

    return (
        <Page>
            <div className="page-seeding">
                <div className="form">
                    <SeedingHeader />
                </div>
                <div className="data">
                    <ComponentTransition show={importMethod == "None"}>
                        <SeedingIntroduction />
                    </ComponentTransition>

                    <ComponentTransition show={importMethod != "None"}>
                        <SeedingProcess />
                    </ComponentTransition>
                </div>

                {importErrors.map(e => (
                    <CustomToast key={e.id} text={e.name} />
                ))}
            </div>

            <SeedingSettingsModal />
            <SeedingUploadToSmashGGModal />
            <SeedingDismissedSwapsModal />
            <SeedingManualSwapModal />
        </Page>
    );
};

export const SeedingPage = () => (
    <SeedingContextProvider>
        <SeedingPageContent />
    </SeedingContextProvider>
);

import clsx from "clsx";
import { useContext } from "react";
import { Tag, ArrowLeft } from "react-bootstrap-icons";
import { SeedingContext } from "../../hooks/useSeedingContext";
import { SeedingPlayerAnalysis } from "../../interfaces/seeding/SeedingInterfaces";

type Props = {
    btn: SeedingPlayerAnalysis;
    showInitialSeed?: boolean;
    className?: string;
};

export const SeedingPlayerButton = (props: Props) => {
    const { btn, showInitialSeed, className } = props;

    const { selectedPlayerAnalysis, setSelectedPlayerAnalysis } = useContext(SeedingContext);

    return (
        <button
            type="button"
            className={clsx(
                "list-group-item",
                "list-group-item-action",
                "d-flex",
                "justify-content-between",
                className,
                selectedPlayerAnalysis?.InitialSeed == btn.InitialSeed && "active",
            )}
            onClick={() => setSelectedPlayerAnalysis(btn)}
        >
            <div className="d-flex justify-content-between flex-grow-1 pe-3">
                <div>
                    <strong>{showInitialSeed ? btn.InitialSeed : btn.Seed}</strong>
                    <span className="px-2">{btn.PlayerName}</span>
                </div>
                <div>
                    {btn.Seed !== btn.InitialSeed && !showInitialSeed && (
                        <span
                            className={clsx(
                                selectedPlayerAnalysis?.PlayerID === btn.PlayerID ? "text-white" : "text-primary",
                            )}
                            title={`${btn.PlayerName} was previously seeded ${btn.InitialSeed}`}
                        >
                            <ArrowLeft className="me-2 ms-3" />
                            <strong>{btn.InitialSeed}</strong>
                        </span>
                    )}
                </div>
            </div>
            {btn.PlayerID != null && (
                <div>
                    <Tag />
                </div>
            )}
        </button>
    );
};
